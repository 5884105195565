import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Flex, Box } from 'reactor-ui'
import useReactor from 'reactor/hooks/useReactor'

import { useStyleConfig } from 'reactor-ui/hooks/useReactorStyleConfig'

import PlexUiDomainSidebarMenu from './PlexUiDomainSidebarMenu'
import PlexUiSingleSidebarFooter from './PlexUiSingleSidebarFooter'
import { PlexUiSingleSidebarWrapper } from './sidebar/PlexUiSidebarComponents'


const PlexUiSingleSidebar = ({
  baseUrl
}) => {
  const reactor = useReactor()
  const layoutConfig = useSelector(state => state.reactorVera.config.layout)

  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])
  const activeDomainName = useSelector(state => state.reactorVera.activeDomainName)
  const activeDomain = activeZone?.domains.find(domain => domain.name == activeDomainName)

  // const DomainMenu = activeDomain && reactor.directory.reactorVera.menuHandlers?.[activeDomain.kind] || RunicDomainSidebarMenu

  if (!activeZone || !activeDomain) return null

  const rootUrl = `${baseUrl}/${activeZone.path}/${activeDomain.path}`

  return (
    <PlexUiSingleSidebarWrapper variant={layoutConfig.sidebar?.extended && 'extended'}>
      <PlexUiDomainSidebarMenu domain={activeDomain} baseUrl={rootUrl} rootUrl={baseUrl}/>
      <PlexUiSingleSidebarFooter/>
    </PlexUiSingleSidebarWrapper>
  )
}



export default PlexUiSingleSidebar