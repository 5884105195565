import React from 'react'

// Reactor
import { Route, Routes, Navigate } from 'reactor-vera/router'
import ReactorVeraApp from 'reactor-vera/ReactorVeraApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'

import rcAppApp from 'elements/rc_app/rcApp'
import rnxMembership from 'elements/rnx_membership/rnxMembership'
import rnxDevice from 'elements/rnx_device/rnxDevice'
import rnxBroadcast from 'elements/rnx_broadcast/rnxBroadcast'
import rnxPac from 'elements/rnx_pac/rnxPac'
import rnxActivitySchedule from 'elements/rnx_activity_schedule/rnxActivitySchedule'
import fmBase from 'elements/fm_base/fmBase'
import rcAuthSimple from 'elements/rc_auth_simple/rcAuthSimple'
import rcForm from 'elements/rc_form/rcForm'

const apps = [
  rcAppApp,
  rnxMembership,
  rnxActivitySchedule,
  rnxDevice,
  rnxBroadcast,
  rnxPac,
  fmBase,
  rcAuthSimple,
  rcForm
]

import FmForms from 'elements/fm_base/fmBase/pages/FmForms'
import FmQr from 'elements/fm_base/fmBase/pages/FmQr'
import MemberHome from 'elements/fm_base/fmBase/pages/MemberHome'
import ChannelSelector from 'elements/fm_base/fmBase/pages/ChannelSelector'
import ChannelLogin from 'elements/rc_app/rcApp/pages/ChannelLogin'


const App = () => (
  <ReactorVeraApp
    storeCreator={storeCreator}
    theme={theme}
    apps={apps}
    config={{
      channel: 'Reactor',
      loginKey: 'TC'
    }}
    homeElement={<ChannelSelector/>}
    routes={[
      <Route path="forms/*" element={<FmForms/>} key='forms' />,
      <Route path="qr/:qrKey" element={<FmQr/>} key='qr' />,
      <Route path="member/:rcTenantId/*" element={<MemberHome/>} key='member' />,
      <Route path="channel/admin/*" element={<ChannelLogin loginKey='E-Posta' channel='Reactor'/>} key='adminLogin' />,
      <Route path="channel/user/*" element={<ChannelLogin loginKey='TC' channel='ReactorPublic' forgotAction='FmBase.ForgotPassword' basePath='/channel/user'/>} key='userLogin' />,
    ]}
  />
)

export default App
