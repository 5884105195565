import SidebarLayout from './components/SidebarLayout'
import SidebarLayoutContent from './pages/SidebarLayoutContent'
import PlexUiDomain from './pages/PlexUiDomain'

import { PlexUiGroupItem, PlexUiComponentItem } from './components/sidebar/PlexUiSidebarComponents'
// import AppUnit from './pages/AppUnit'


export default {
  name: 'reactor-vera.ui',
  directory: {
    reactorVera: {
      // unitHandlers: {
      //   r2: AppUnit
      // },
      components: {
        SidebarLayout,
        PlexUiDomain,
        PlexUiGroupItem,
        PlexUiComponentItem,
        SidebarLayoutContent
      },
      // domainHandlers: {
      //   StandardDomain: RunicDomain
      // }
    }
  },
}
