import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const FormManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<RcFormHome />} />
      <RV.Route path='form/:rcFormId/*' element={<RcFormDetail />} />
    </RV.Routes>
  )
}

const RcFormHome = props => {
  const params = RV.useParams()
  return (
    <>
      <RV.GraphTable
        modelName='RcForm'
        detailLink='form'
      // query={{
      //   filters: {
      //     parentId: null
      //   }
      // }}
      />
    </>
  )
}

const RcFormDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RcForm'
      routes={[
        entity => (
          <>
            <RV.Route path='/' element={(
              <RV.BareTabs
                isFitted
                isLazy
                tabs={[
                  {
                    name: 'Veriler',
                    codeName: 'records',
                    element: (
                      <RV.GraphTable
                        modelName={'RcFormData'}
                        detailLink={'record'}
                        query={{
                          filters: {
                            rc_form_id: entity.id
                          }
                        }}
                      />
                    )
                  },
                ]}
              />
            )} />

            <RV.Route path='record/:rcFormDataId' element={(
              <>
                <FormData />
              </>
            )} />
          </>
        )
      ]}
    />
  )
}

const FormData = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'rcFormData': {
      filters: {
        id: params.rcFormDataId
      },
      fields: [
        'name', 'status', 'values', 'formFields'
      ]
    }
  })

  return (
    <Box>
      <RV.Link to='../' sx={{
        color: 'brand.500'
      }}>Geri dön</RV.Link>
      <RV.Card>
        <RV.QueryLoading result={result}>
          {result.graph?.rcFormData && (
            <Box>
              <Flex alignItems='center'>
              <RV.Card.Header mr={4}>
              {result.graph.rcFormData.name}
              </RV.Card.Header>

              <RV.Enum value={result.graph.rcFormData.status}/>

              <RV.ActionGroupMenu name={'RcFormData'} contexts={['Entity']} ctx={{ id: result.graph.rcFormData.id }}/>
              </Flex>

              {result.graph.rcFormData.formFields.map((f, dx) => {
                const kind = f.kind.toLowerCase()
                if (kind === 'password') return null
                const value = result.graph.rcFormData.values[f.code_name]
                let valueContent

                if (!value) {
                  valueContent = ''
                }
                else if (kind === 'file') {
                  valueContent = (
                    <>
                      <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                      <LightboxImage src={value.url} sx={{
                        width: '200px'
                      }} />
                    </>
                  )
                } else if (kind === 'entity') {
                  valueContent = value.name
                } else if (kind === 'radio') {
                  valueContent = <Enum value={value} />
                } else if (kind === 'bool') {
                  valueContent = <Bool value={value} />
                } else {
                  valueContent = value
                }
                return (
                  <Box key={dx} sx={{
                    my: 2
                  }}>
                    <Box fontWeight='bold'>
                      {f.name}
                    </Box>

                    <Box>
                      {valueContent}
                    </Box>
                  </Box>
                )
              })}

              <RV.ReactorServerAction identifier='FmBase.RespondToSupportRequest' options={{ ctx: { id: params.rcFormDataId } }} />
            </Box>
          )}
        </RV.QueryLoading>
      </RV.Card>
    </Box>
  )
}

export default FormManagement