import React from 'react'

// Vendor
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { setIn, getIn } from 'final-form'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import DateTime from 'reactor-ui/components/DateTime'


const widthMap = {
  MINI: 50,
  SMALL: 30,
  DEFAULT: 100,
  MEDIUM: 200,
  LARGE: 500
}

const SimpleTable = ({
  items,
  bottomItems,
  columns,
  actions,
  sx,
  alignLast,
  maxHeight,
  header,
  modelActions,
  sortFn,
  refresh,
  isFetching,
  hasStripe = true,
  overTableContent
}) => {
  if (!items) return null

  const extraHeaderHeight = 0
  const rowHeight = 60
  let rowCount = items.length
  if (bottomItems) rowCount += bottomItems.length
  const headerHeight = 40 + extraHeaderHeight
  const calculatedHeight = (rowHeight * rowCount)

  const calculatedMaxHeight = maxHeight === undefined ? window.innerHeight - 160 - extraHeaderHeight : maxHeight
  const tableHeight = calculatedMaxHeight ? calculatedHeight < calculatedMaxHeight ? calculatedHeight : calculatedMaxHeight : calculatedHeight

  const cols = columns.map(({ label, key, widthKey, flexGrow, ...rest }) => {
    if (key == '@dx') {
      if (!widthKey) widthKey = 'MINI'
      if (!flexGrow) flexGrow = 0
    }

    return {
      label,
      key,
      widthKey,
      flexGrow,
      ...rest
    }
  })

  let rows = items.map(item => {
    const val = { ...item }
    cols.forEach(col => {
      if (col.getter) return val[col.key] = col.getter(item)
    })
    return val
  })

  if (sortFn) rows = rows.sort(sortFn)

  if (bottomItems) {
    rows = [...rows, ...bottomItems.map(item => {
      const val = { ...item }
      cols.forEach(col => {
        if (col.getter) return val[col.key] = col.getter(item)
      })
      return val
    })]
  }

  return (
    <Box sx={{
      variant: 'layout.card',
      ...sx
    }}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <>
            <Flex sx={{
              borderBottom: '1px solid #FFF',
              borderBottomColor: 'background3',
              // height: headerHeight,
              width: width,
              pb: 3,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Flex sx={{
                alignItems: 'center'
              }}>
                <Box sx={{
                  fontWeight: 'semiBold'
                }}>
                  {header}
                </Box>
                {refresh && <Box sx={{
                  ml: 2
                }}>
                  <Button iconName='refresh' iconSize={16} colorScheme='brand' onClick={refresh} isLoading={isFetching} />
                </Box>}
              </Flex>
              <Flex alignItems={'center'}>
                {modelActions}
              </Flex>
            </Flex>

            {overTableContent}

            <Flex sx={{
              height: headerHeight,
              width: width,

              borderBottom: '1px solid #FFF',
              borderBottomColor: 'background3',
            }}>
              {cols.map(({ label, key, widthKey, flexGrow }, colDx) => {
                return (
                  <Box sx={{
                    flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
                    py: 15,
                    color: 'textMuted',
                    textTransform: 'uppercase',
                    fontSize: 's'
                  }} key={key}>
                    {alignLast && colDx === cols.length - 1 ? (
                      <Box sx={{
                        float: 'right',
                        display: 'inline-block',
                      }}>
                        {label}
                      </Box>
                    ) : (
                      label
                    )}
                  </Box>
                )
              })}
            </Flex>

            <List
              itemData={{ items: rows, columns: cols, actions, count: rows?.length, alignLast, hasStripe }}
              itemKey={(dx, data) => data.items[dx].id}
              itemSize={rowHeight}
              itemCount={rowCount}
              height={tableHeight}
              width={width}
            >
              {Row}
            </List>
          </>
        )}
      </AutoSizer>
    </Box>
  )
}

const Row = ({
  data,
  index,
  style,
}) => {
  const item = data.items[index]
  const hasStripe = data.hasStripe && index % 2 === 0
  return (
    <Flex style={style} sx={{
      borderBottom: '1px solid #FFF',
      borderBottomColor: 'light2',
      alignItems: 'center',
      bg: hasStripe ? 'background6' : null,
      '&:hover': {
        bg: 'background4',
      }
    }}>
      {data.columns.map(({ label, key, widthKey, flexGrow, kind, renderer }, colDx) => {
        let content
        if (key == '@dx') content = index + 1
        else if (renderer) content = renderer(item, key)
        else if (key == '@actions') content = data.actions?.map((act, dx) => act(item, dx))
        else content = getIn(item, key)

        if (kind == 'date') content = <DateTime data={content} />
        return (
          <Box sx={{
            flex: `${flexGrow !== undefined ? flexGrow : 1} 1 ${widthMap[widthKey || 'DEFAULT']}px`,
            // fontWeight: 'bold'
          }} key={key}>
            {data.alignLast && colDx === data.columns.length - 1 ? (
              <Box sx={{
                float: 'right',
                display: 'inline-block'
              }}>
                {content}
              </Box>
            ) : (
              content
            )}
          </Box>
        )
      })}
    </Flex>
  )
}

export default SimpleTable