import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import * as RV from 'reactor-vera/templates/v1'


const ProfileManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={<ProfileHome />} />
      <RV.Route path='device_profile_detail/:rnxDeviceProfileId/*' element={<DeviceProfileDetail />} />
      <RV.Route path='media_profile_detail/:rnxDisplayMediaProfileId/*' element={<MediaProfileDetail />} />
      <RV.Route path='register_profile_detail/:rnxDeviceRegisterProfileId/*' element={<RegisterProfileDetail />} />
    </RV.Routes>
  )
}

const ProfileHome = props => {
  return (
    <RV.BareTabs
      isFitted
      isLazy
      tabs={[
        {
          name: 'Cihaz Profil',
          codeName: 'deviceProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDeviceProfile'}
              detailLink={'device_profile_detail'}
              actionCtx={{
                model: {
                  // rnx_device_app: params.rnxDeviceAppId,
                }
              }}
            />
          )
        },

        {
          name: 'Medya Profil',
          codeName: 'mediaProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDisplayMediaProfile'}
              detailLink={'media_profile_detail'}
              actionCtx={{
                model: {
                  // rnx_device_app: params.rnxDeviceAppId,
                }
              }}
            />
          )
        },

        {
          name: 'Kayıt Profil',
          codeName: 'registerProfile',
          element: (
            <RV.GraphTable
              modelName={'RnxDeviceRegisterProfile'}
              detailLink={'register_profile_detail'}
            />
          )
        }
      ]}
    />
  )
}

const DeviceProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceProfile': {
      filters: {
        id: params.rnxDeviceProfileId
      },
      fields: [
        'name', 'kind', 'platform', 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceProfile}`, result.graph?.rnxDeviceProfile?.name)
  const rnxDeviceProfile = result.graph?.rnxDeviceProfile
  const meta = result.graph?.rnxDeviceProfileMeta
  if (!rnxDeviceProfile) return null

  const profileData = rnxDeviceProfile.data?.profile

  return (
    <>
      <RV.EntityDetail
        icon={'graphic-tablet-wireless'}
        entity={rnxDeviceProfile}
        schema={result.schema?.rnxDeviceProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
      <Flex sx={{
        mx: -2
      }}>

        {profileData && <RV.Card sx={{
          flex: '0 0 300px',
          my: 3,
          mx: 2
        }}>
          <Flex sx={{
            justifyContent: 'space-between'
          }}>
            <RV.Card.Header>
              Profil Bilgileri
            </RV.Card.Header>

            {/* <RV.RfSingleAction
              onSuccess={result.refresh}
              iconName='pencil'
              actionKey='RnxDevice.SetProfileData'
              fields={result.graph?.rnx_device_profile?.profile_data.fields}
              initialValues={{
                ...profileData,
                id: result.graph?.rnx_device_profile?.id
              }}
              buttonText='KAYDET' /> */}
          </Flex>
          {Object.entries(profileData).map(([k, v]) => {
            return (
              <Flex key={k} sx={{
                my: 2
              }}>
                <Box sx={{
                  textTransform: 'uppercase',
                  flex: '0 0 80%',
                  color: 'textMuted',
                  fontWeight: 'semiBold'
                }}>
                  {t(`field.${k}`)}
                </Box>
                <Box>{v}</Box>
              </Flex>
            )
          })}
        </RV.Card>}

        <RV.GraphTable
          sx={{
            my: 3,
            mx: 2,
            flex: '1 1 0'
          }}
          modelName={'RnxDevice'}
          query={{
            filters: {
              rnx_device_profile_id: params.rnxDeviceProfileId
            }
          }}
        />

      </Flex>
    </>
  )
}

const MediaProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDisplayMediaProfile': {
      filters: {
        id: params.rnxDisplayMediaProfileId
      },
      fields: [
        'name', 'displayCount', 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDisplayMediaProfile}`, result.graph?.rnxDisplayMediaProfile?.name)
  const rnxDisplayMediaProfile = result.graph?.rnxDisplayMediaProfile
  const meta = result.graph?.rnxDisplayMediaProfileMeta
  if (!rnxDisplayMediaProfile) return null

  return (
    <>
      <RV.EntityDetail
        icon={'playlist-video'}
        entity={rnxDisplayMediaProfile}
        schema={result.schema?.rnxDisplayMediaProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 3
      }}>
        <RV.Card.Header>
          Video Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.videoProfile ? (
            <Box>
              CRF: {rnxDisplayMediaProfile.data.videoProfile.crf},
              Scale: {rnxDisplayMediaProfile.data.videoProfile.scale}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>

        <RV.Card.Header>
          Görsel Profil
        </RV.Card.Header>

        <Box>
          {rnxDisplayMediaProfile.data.imageProfile ? (
            <Box>
              Max Genişlik: {rnxDisplayMediaProfile.data.imageProfile.maxWidth},
              Max Yükseklik: {rnxDisplayMediaProfile.data.imageProfile.maxHeight},
              Kalite: {rnxDisplayMediaProfile.data.imageProfile.quality}
            </Box>
          ) : (
            'Yok'
          )}
        </Box>
      </RV.Card>
    </>
  )
}

const RegisterProfileDetail = ({

}) => {
  const { t } = useTranslation()
  const params = RV.useParams()
  const result = RV.useModelDetailReactorQuery({
    'rnxDeviceRegisterProfile': {
      filters: {
        id: params.rnxDeviceRegisterProfileId
      },
      fields: [
        'name', 'deviceKind', 'platform', {'rnxDeviceAppProfile': {fields: ['name']}}, {'rnxDeviceProfile': {fields: ['name']}}, 'data'
      ]
    },
  })

  RV.useBreadcrumb(`rnx_device_app:${result.graph?.rnxDeviceRegisterProfile}`, result.graph?.rnxDeviceRegisterProfile?.name)
  const rnxDeviceRegisterProfile = result.graph?.rnxDeviceRegisterProfile
  const meta = result.graph?.rnxDeviceRegisterProfileMeta
  if (!rnxDeviceRegisterProfile) return null

  const linkData = rnxDeviceRegisterProfile.data?.links

  return (
    <>
      <RV.EntityDetail
        icon={'playlist-video'}
        entity={rnxDeviceRegisterProfile}
        schema={result.schema?.rnxDeviceRegisterProfile}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 3,
        width: 300
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <RV.Card.Header>
          Bağlantılar
          </RV.Card.Header>

          {rnxDeviceRegisterProfile.deviceKind?.attrs && <RV.RfActionForm
            onSuccess={result.refresh}
            iconName='pencil'
            header='Düzenle'
            actionKey='RnxDevice.SetLinkData'
            fields={rnxDeviceRegisterProfile.deviceKind?.attrs?.register_links}
            initialValues={{
              // data: {...linkData},
              id: rnxDeviceRegisterProfile?.id
            }}
            buttonText='KAYDET' />}
        </Flex>
        {linkData && Object.entries(linkData).map(([k, v]) => {
          return (
            <Flex key={k} sx={{
              my: 2
            }}>
              <Box sx={{
                textTransform: 'uppercase',
                flex: '1 1 0',
                color: 'textMuted',
                fontWeight: 'semiBold'
              }} >
                {t(`field.${k}`)}
                </Box>
              <Box>{v}</Box>
            </Flex>
          )
        })}
      </RV.Card>
    </>
  )
}

export default ProfileManagement