import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'

import { Link } from 'reactor-vera/router'



const rnxBroadcastManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<DeviceManagementHome />} />
      <Route path='broadcast/:rnxBroadcastId/*' element={<DeviceManagementDetail />} />
    </Routes>
  )
}

const DeviceManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <GraphTable
        modelName='RnxBroadcast'
        detailLink='broadcast'
      />
    </>
  )
}

const DeviceManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxBroadcast': {
      filters: {
        id: params.rnxBroadcastId
      }
    },
  })

  useBreadcrumb(`rnxBroadcastDetail:${result.graph?.rnxBroadcast.id}`, result.graph?.rnxBroadcast.name)

  const rnxBroadcast = result.graph?.rnxBroadcast
  const meta = result.graph?.rnxBroadcastMeta

  if (!rnxBroadcast) return null

  return (
    <>
      <EntityDetail
        icon={'book-open'}
        entity={rnxBroadcast}
        schema={result.schema?.rnxBroadcast}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
{/*
      <BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Program',
            codeName: 'program',
            element: <ActivityProgram rnxBroadcast={rnxBroadcast} />
          },

          {
            name: 'Türler',
            codeName: 'kinds',
            element: <ActivityKinds rnxBroadcast={rnxBroadcast} />
          }
        ]}
      />*/}

    </>
  )
}

export default rnxBroadcastManagement