import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const AccessPlanManagement = props => {
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.GraphTable
          modelName={'RnxPacAccessPlan'}
          detailLink={'plan'}
        // actionCtx={{
        //   model: {
        //     // rnx_device_app: params.rnxDeviceAppId,
        //   }
        // }}
        />
      )} />

      <RV.Route path='plan/:rnxPacAccessPlanId/*' element={(
        <RV.EntityDetailPage
          modelName='RnxPacAccessPlan'
          routes={[
            entity => (
              <RV.Route path='/' element={(
                <RV.BareTabs
                  isFitted
                  isLazy
                  tabs={[
                    {
                      name: 'Geçişler',
                      codeName: 'records',
                      element: (
                        <RV.GraphTable
                          modelName={'RnxPacAccessRecord'}
                          // detailLink={'point'}
                          query={{
                            filters: {
                              rnx_pac_access_plan_id: entity.id
                            }
                          }}
                        />
                      )
                    },

                    {
                      name: 'Cihazlar',
                      codeName: 'points',
                      element: (
                        <RV.GraphTable
                          modelName={'RnxPacAccessPoint'}
                          // detailLink={'point'}
                          query={{
                            filters: {
                              rnx_pac_access_plan_id: entity.id
                            }
                          }}
                        />
                      )
                    }
                  ]}
                />
              )} />
            )
          ]}
        />
      )} />
    </RV.Routes>
  )
}

export default AccessPlanManagement