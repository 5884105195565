import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Portal from 'reactor-ui/components/Portal'

// Reactor
import useReactor from 'reactor/hooks/useReactor'
import { Link, useLocation, useResolvedPath } from 'reactor-vera/router'
import useLayoutConfig from 'reactor-vera/hooks/useLayoutConfig'

// import { }


const PlexUiDomainSidebarMenu = ({
  domain,
  baseUrl,
  rootUrl
}) => {
  const reactor = useReactor()
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended
  let name

  if (isExtended) {
    name = (
      <Icon icon={domain.icon} wrapperSx={{
        color: 'brandLight.500',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        flex: '1 1 0',
        my: isExtended ? 0 : 2
      }} childSx={{
        ml: 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '80%',
        display: 'inline-block',
      }}>
        {domain.name}
      </Icon>
    )
  } else {
    name = (
      <>
        <Box sx={{
          mt: 2
        }} />
        <Icon color='brandLight.500' icon={domain.icon} wrapperSx={{
        }} />
        <Box sx={{
          color: 'brandLight.500',
          fontSize: 'sm',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'inline-block',
        }}>
          {domain.name}
        </Box>
        <Box sx={{
          mt: 2
        }} />
      </>
    )
  }
  const footerHeight = isExtended ? '50px' : '70px'
  return (
    <Box sx={{
      // height: `calc(100vh - ${footerHeight})`,
        pb: isExtended ? '50px' : '70px'
    }}>
      <Flex sx={{
        bg: 'dark.900',
        mx: isExtended ? -3 : -2,
        px: isExtended ? 3 : 1,
        py: 3,
        alignItems: 'center',
        flexDirection: isExtended ? 'row' : 'column'
      }}>
        <ExtensionButton />
        {name}
        <SidebarZoneMenu rootUrl={rootUrl} />
      </Flex>

      {domain.items.map((item, dx) => {
        const Component = reactor.directory.reactorVera?.components?.[item.componentKey]

        return (
          <Box key={dx}>
            <Component item={item} depth={0} baseUrl={baseUrl} />
          </Box>
        )
      })}
    </Box>
  )
}

const ExtensionButton = ({

}) => {
  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended
  const iconName = isExtended ? 'chevron-left' : 'chevron-right'

  return (
    <Button onClick={() => {
      updateLayoutConfig({
        sidebar: {
          extended: !isExtended
        }
      })
    }} colorScheme='dark' iconColor='brandLight.500' iconName={iconName} size='xs' sx={{
      flex: '0 0 10px',
      mr: 2,
    }} />
  )
}

import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/menu'
import Button from 'reactor-ui/components/Button'

const SidebarZoneMenu = ({
  rootUrl
}) => {
  const zones = useSelector(state => state.reactorVera.zones)
  const activeZone = useSelector(state => state.reactorVera.zones[state.reactorVera.activeZoneName])

  const hasMultipleDomains = activeZone.domains.length > 1
  const hasMultipleZones = Object.keys(zones).length > 1

  const [layoutConfig, updateLayoutConfig] = useLayoutConfig()
  const isExtended = layoutConfig?.sidebar?.extended

  if (!hasMultipleDomains && !hasMultipleZones) return null

  return (
    <Box>
      <Menu isLazy>
        <MenuButton size='sm'
        // rightIcon={<ChevronDownIcon />}
        >
          <Flex alignItems={'center'} sx={{
            fontSize: 'xs',
          }}>
            <Icon name='chevron-down' size={'12px'} color='brandLight.500' sx={{
              ml: isExtended ? 1 : 0
            }} />
          </Flex>
        </MenuButton>
        {/* <Box color='brandLight.500'> */}
        <Portal>
          <MenuList>
            {/* <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış Yap</MenuItem> */}
            <Box mx={2}>
              <Box sx={{
                fontWeight: 'bold',
              }}>
                Alanlar
              </Box>
              {activeZone.domains.map((domain, dx) => {
                return (
                  <MenuItem as={Link} key={dx} to={`${rootUrl}/${activeZone.path}/${domain.indexPath}`} sx={{
                    display: 'block'
                  }}>
                    {domain.name}
                  </MenuItem>
                )
              })}
            </Box>

            <Box mx={2} mt={2}>
              <Box sx={{
                fontWeight: 'bold',
              }}>
                Uygulamalar
              </Box>
              {Object.values(zones).map((zone, dx) => {
                return (
                  <MenuItem as={Link} key={dx} to={`${rootUrl}/${zone.indexPath}`} sx={{
                    display: 'block'
                  }}>
                    {zone.name}
                  </MenuItem>
                )
              })}
            </Box>
          </MenuList>
        </Portal>
        {/* </Box> */}
      </Menu>
    </Box>
  )
}

export default PlexUiDomainSidebarMenu