import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import useReactor from 'reactor/hooks/useReactor'
import { useParams, useMatch, useResolvedPath, Route, Routes } from 'reactor-vera/router'
import { useRncActivate } from 'reactor-vera/apps/ui/hooks/tree'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import Breadcrumb from '../components/Breadcrumb'

const PlexUiUnitRouter = ({
  domain,
  zone,
  isSubRoute
}) => {
  const reactor = useReactor()
  const params = useParams()

  const { unitName } = params
  const unitNameSplit = unitName.split('.')
  let unit = domain
  unitNameSplit.forEach(key => {
    if (!unit) return
    unit = unit.items.find(i => i.path === key)
  })

  if (!unit) return null
  const layoutConfig = useSelector(state => state.reactorVera.config.layout)
  const ContentComponent = reactor.directory.reactorVera?.components?.[layoutConfig.contentComponentKey]

  if (unit.mode === 'entity') {

    let WrapperComponent = React.Fragment
    if (unit.options?.wrapperComponentKey) {
      WrapperComponent = reactor.components?.[unit.options?.wrapperComponentKey]
      if (!WrapperComponent) {
        // FIXME: display erro message
        console.warn('unit handler not found for wrapper', unit.options?.wrapperComponentKey, reactor.components)
        return null
      }
    }
    return (
      <ContentComponent>
        <Breadcrumb sx={{
          pt: 3,
          pb: 3
        }} />
        <Routes>
          <Route path={`${unit.options.requiredRoutePath}/:unitName/*`} element={<WrapperComponent><PlexUiUnitRouter isSubRoute domain={domain} zone={zone} /></WrapperComponent>} />
          {/* <Route path={`${unit.options.requiredRoutePath}/:unitName/*`} element={<PlexUiEntityUnit />}/> */}
        </Routes>
      </ContentComponent>
    )
  }

  if (isSubRoute) return <PlexUiUnit unit={unit} />

  return (
    <ContentComponent>
      <Breadcrumb sx={{
        pt: 3,
        pb: 3
      }} />
      <PlexUiUnit unit={unit} />
    </ContentComponent>
  )
}

const PlexUiUnit = ({
  unit
}) => {
  const { t } = useTranslation()
  const reactor = useReactor()
  const componentKey = `${unit.appName}.${unit.name}`

  useRncActivate('unit', unit)
  // useBreadcrumb(unit.name, <Text textCase='title'>{`label.${changeCase.snake(unit.displayName)}`}</Text>)
  useBreadcrumb(unit.name, t(`label.${changeCase.snake(unit.name)}`))

  const Component = reactor.components?.[componentKey]
  if (!Component) {
    // FIXME: display erro message
    console.warn('unit handler not found', componentKey, reactor.components)
    return null
  }

  let WrapperComponent
  if (unit.options?.wrapperComponentKey) {
    const WrapperComponent = reactor.components?.[unit.options?.wrapperComponentKey]
    if (!WrapperComponent) {
      // FIXME: display erro message
      console.warn('unit handler not found for wrapper', unit.options?.wrapperComponentKey, reactor.components)
      return null
    }
  }

  return (
    <Component unit={unit} />
  )
}

export default PlexUiUnitRouter
