import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import Alert from 'reactor-ui/components/Alert'


const RfFormError = ({
  error,
  errorMap
}) => {
  const { t } = useTranslation()
  if (!error) return null
  const message = t(`error.${error.kind || 'default'}`)

  return (
    <Alert sx={{
      mb: 2
    }}>
      {message}
      {error.kind && errorMap?.[error.kind]}
    </Alert>
  )
}

export default RfFormError