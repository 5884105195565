import React, { cloneElement } from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import * as changeCase from 'reactor-ui/util/text'




const AuthorizationManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<AuthorizationHome />} />
      <Route path='role/:rcAuthSimpleRoleId/*' element={<RoleDetail />} />
    </Routes>
  )
}

const AuthorizationHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <GraphTable
        modelName='RcAuthSimpleRole'
        detailLink='role'
      />
    </>
  )
}


const RoleDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rcAuthSimpleRole': {
      filters: {
        id: params.rcAuthSimpleRoleId
      },
      fields: [
        'name', 'description', 'status', 'resourcePermissions', 'allPermissions'
      ]
    },
  })

  useBreadcrumb(`rcAuthSimpleRoleDetail:${result.graph?.rcAuthSimpleRole.id}`, result.graph?.rcAuthSimpleRole.name)

  const rcAuthSimpleRole = result.graph?.rcAuthSimpleRole
  const meta = result.graph?.rcAuthSimpleRoleMeta

  if (!rcAuthSimpleRole) return null

  return (
    <>
      <EntityDetail
        icon={'lock'}
        entity={rcAuthSimpleRole}
        schema={result.schema?.rcAuthSimpleRole}
        fields={meta.primaryFields}
        key={result?.status?.queryKey}
      // refresh={result.refresh}
      // status={result.status}
      />


      <BareTabs
        isLazy
        memoKey={result.graph}
        tabs={[
          {
            name: 'Yetkiler',
            codeName: 'permissions',
            element: <Permissions rcAuthSimpleRole={rcAuthSimpleRole} />
          },

          {
            name: 'Kullanıcılar',
            codeName: 'users',
            element: (
              <GraphTable
                modelName='RcAuthSimpleUserRole'
                query={{
                  filters: {
                    'rcAuthSimpleRole.id': rcAuthSimpleRole.id
                  }
                }}
              />
            )
          }
        ]}
      />

    </>
  )
}

const Permissions = ({
  rcAuthSimpleRole
}) => {
  const { t, i18n } = useTranslation()

  return (
    <Card my={4}>
      <Card.Header>Yetkiler</Card.Header>

      {rcAuthSimpleRole.allPermissions && (
        Object.entries(rcAuthSimpleRole.allPermissions).map(([resource, permissions]) => {
          return (
            <Box key={resource} sx={{
              my: 4
            }}>
              <Box sx={{
                fontWeight: 'bold'
              }}>
                {t(`label.${changeCase.snake(resource)}`)}
              </Box>

              <Box>
                {permissions.map(p => {
                  console.log('o', i18n.exists(`permission.${p}.description`), t(`permission.${p}.description`))
                  return (
                    <Box key={p} my={2}>
                      <Icon color={rcAuthSimpleRole.resourcePermissions?.[resource]?.includes(p) ? 'success.500' : 'danger.500'} name={rcAuthSimpleRole.resourcePermissions?.[resource]?.includes(p) ? 'check' : 'circle-remove'}>
                        {t(`permission.${p}`)}
                      </Icon>
                      <Box sx={{
                        fontSize: 'sm',
                        color: 'dark.300',
                        ml: 7
                      }}>
                      {i18n.exists(`permission.${p}.description`) && t(`permission.${p}.description`)}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )
        })
      )}
    </Card>
  )
}

export default AuthorizationManagement