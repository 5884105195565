import React from 'react'

// ReactorUi
import { Flex, Box } from 'reactor-ui'

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "./Tabs"


const BareTabs = ({
  tabs,
  ctx,
  isFitted,
  isLazy,
  initialTabCodeName,
  activeTabCodename,
  sx,
  tabListSx,
  // tabSx,
  memoKey
}) => {
  const [active, setActive] = React.useState(initialTabCodeName ? tabs.find(x => x.codeName === initialTabCodeName) : tabs[0].codeName)
  // const activeTab = active ? tabs.find(x => x.codeName === active) : tabs[0]
  const activeTab = activeTabCodename ?
    tabs.find(x => x.codeName === active) : active
    ? tabs.find(x => x.codeName === active) : tabs[0]

  const tabComponents = React.useMemo(() => {
    const tabList = []
    const tabPanels = []
    tabs.forEach((tab, dx) => {
      tabList.push(
        <Tab key={dx} _selected={{
          fontWeight: 'bold',
          borderBottomColor: 'brand.500',
          color: 'brand.500'
        }} _hover={{
          fontWeight: 'bold',
          borderBottomColor: 'brand.200',
          color: 'brand.400'
        }} sx={{
          color: 'dark.300',
          px: 6
        }}>
          <Box sx={{
            // color: 'dark.400',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: 'sm',
            letterSpacing: '-0.03em'
          }}>
          {tab.name}
          </Box>
        </Tab>
      )
      tabPanels.push(
        <TabPanel p={0} key={dx}>
          {tab.element}
        </TabPanel>
      )
    })

    return {tabList, tabPanels}
  }, [memoKey])

  return (
    <Tabs isFitted={isFitted} isLazy={isLazy} sx={sx} >
      <TabList sx={tabListSx}>
        {tabComponents.tabList}
      </TabList>

      <TabPanels>
        {tabComponents.tabPanels}
      </TabPanels>
    </Tabs>
  )

  return (
    <Box>
      <Flex mx={-2} mb={3}>
        {tabs.map((tab, dx) => {
          const isActive = activeTab.codeName === tab.codeName
          return (
            <Box key={dx} as='a' onClick={() => setActive(tab.codeName)} sx={{
              mx: 2,
              display: 'inlineBlock',
              borderBottom: isActive ? '3px solid #FFF' : '1px solid #FFF',
              borderBottomColor: 'primary',
              textAlign: 'center',
              py: 2,
              px: 3,
              transition: 'all 0.1s linear',
            }}>
              {tab.name}
            </Box>
          )
        })}
      </Flex>

      <Box>
        <Tab tab={activeTab} ctx={ctx} />
      </Box>
    </Box>
  )
}

// const Tab = ({
//   tab,
//   ctx
// }) => {
//   const element = React.cloneElement(tab.element, {tab, ctx})
//   return (
//     element
//   )
// }

export default BareTabs