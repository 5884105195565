import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Loader from 'reactor-vera/apps/ui/components/Loader'


const QueryLoading = ({
  result,
  children,
  sx
}) => {
  if (result?.status?.status === 'READY') return children || null
  return (
    <Box sx={sx}>
      <Loader size={20} color='dark.500'>
      Yükleniyor.
      </Loader>
    </Box>
  )
}

export default QueryLoading