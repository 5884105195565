import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import Icon from 'reactor-ui/components/Icon'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "reactor-ui/components/Modal"

import {
  MenuItem
} from 'reactor-ui/components/menu'


// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, useFormState } from 'reactor-form/components/RfForm'
import { isFunction } from 'lodash'


const RfActionForm = ({
  name,
  actionKey,
  header,
  initialValues,
  displayAs = 'MODAL',
  onSuccess,
  onError,
  buttonText = 'btn.save',
  buttonColorScheme = 'brand',
  fields,
  anchorKind,
  anchor,
  onClose,
  displayProps,
  elements,
  icon,
  sx,
  isResetEnabled = true,
  fieldElements,
  closeOnSuccess,
  formComponent,
  size = 'lg',
  ctx,
  ...rest
}) => {
  const { t } = useTranslation()
  const hasUpload = React.useMemo(() => {
    let hasUpload = false
    fields?.forEach(field => {
      if (field.kind === 'file') hasUpload = true
    })
    return hasUpload
  }, [])
  const disclosure = useDisclosure()
  const [formProps, isSubmitting, reset] = useReactorActionForm(
    actionKey,
    {
      hasUpload,
      initialValues,
      onSuccess: (data) => {
        onSuccess?.(data)
        closeOnSuccess && disclosure.onClose()
      },
      onError
    }
  )

  let content
  let footerButtons = []

  if (formProps.phase === 'SUCCESS') {
    isResetEnabled && footerButtons.push(
      <Button key='reset' variant='ghost' colorScheme='brand' onClick={reset}>
        {t('btn.refill_form_again')}
      </Button>
    )
    content = elements?.success || (
      <Box>
        <Alert status='success' sx={{ textTransform: 'capitalize' }}>
          {elements?.successMessage ?
            isFunction(elements.successMessage) ? elements?.successMessage?.(formProps.result) : elements.successMessage
            :
            t(formProps.result?.message || 'text.SUCCESS')
          }
        </Alert>
      </Box>
    )
  } else {
    content = (
      <Form {...formProps} ctx={ctx}>
        {fields?.map(({ label, optional, ...field }, dx) => {
          return (
            <RfFormField
            sx={{ textTransform: 'capitalize' }}
            required={!optional}
            optional={optional}
            // label={t(label)}
            label={label}
            {...field}
            key={dx}
            />
          )
        })}
        {formComponent}
        {fieldElements}
        <RfFormError error={formProps.error} />
        <Button mt={3} textTransform='capitalize' colorScheme={buttonColorScheme} tabIndex={4} submit isLoading={isSubmitting} isFullWidth>
          {t(buttonText)}
        </Button>
      </Form>
    )
  }

  if (!displayAs || displayAs === 'MODAL') {
    return (
      <ModalAction size={size} disclosure={disclosure} icon={icon} sx={sx} onClose={() => {
        console.log('reset')
        reset()
        onClose?.()
      }} anchor={anchor} anchorKind={anchorKind} header={header || t(name)} footerButtons={footerButtons}>
        {content}
      </ModalAction>
    )
  } else if (displayAs === 'MENU') {
    return (
      <ModalAction size={size} disclosure={disclosure} icon={icon} sx={sx} onClose={() => {
        reset()
        onClose?.()
      }} anchor={anchor} anchorKind={anchorKind} header={header || t(name)} footerButtons={footerButtons}>
        {content}
      </ModalAction>
    )
  } else if (displayAs === 'FORM') {
    return (
      <Box sx={sx}>
        {name ? t(name) : <Icon name={icon} />}
        {content}
        {onClose && <Button sx={{ textTransform: 'capitalize' }} variant='ghost' onClick={onClose}>
          {t('op.close')}
        </Button>}
      </Box>
    )
  } else {
    return `${displayAs}`
  }

  return null
}

const ModalAction = ({
  disclosure,
  children,
  header,
  footerButtons,
  onClose: onCloseFromProps,
  anchorKind = 'BUTTON',
  anchor: anchorFromProps,
  icon,
  size = 'lg',
  sx
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = disclosure

  let anchor
  if (anchorFromProps) {
    anchor = React.cloneElement(anchorFromProps, { onClick: onOpen })
  } else {
    const anchorContent = icon ? (
      header ? <Icon name={icon}>{header}</Icon> : <Icon name={icon} />
    ) : header
    if (anchorKind === 'BUTTON') {
      anchor = <Button size='sm' textTransform='capitalize' colorScheme={'brand'} onClick={onOpen}>{anchorContent}</Button>
    } else if (anchorKind === 'LINK') {
      anchor = <Button variant='link' textTransform='capitalize' colorScheme={'brand'} onClick={onOpen}>{anchorContent}</Button>
    } else if (anchorKind === 'MENUITEM') {
      anchor = (
        <MenuItem onClick={onOpen}>{anchorContent}</MenuItem>
      )
    }
  }
  return (
    <Box sx={sx}>
      {anchor}

      <Modal
        autoFocus={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          onCloseFromProps?.()
          onClose()
        }}
        size={size}
        trapFocus={false}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform='capitalize'>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>

          <ModalFooter>
            {footerButtons}
            <Button sx={{ textTransform: 'capitalize' }} variant='ghost' onClick={() => {
              onCloseFromProps?.()
              onClose()
            }}>
              {t('op.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default RfActionForm