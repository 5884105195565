// Vendor
import { createSelector } from 'reselect'
import isArray from 'lodash/isArray'


const getDependencyKeys = (state, fetchKey) => state.reactor.graph.data?.[fetchKey]?.dependencies
const getTypes = (state) => state.reactor.graph.types
const getGraphData = (state, fetchKey) => state.reactor.graph.data[fetchKey]

const dependencySelector = createSelector(
  getDependencyKeys,
  getTypes,
  (keys, types) => {
    if (!keys) return null
    const result = {}
    keys.forEach(key => {
      result[key] = types[key]
    })
    return result
  }
)

const isListGraph = data => data?.__kind === 'list'
const isTypeGraph = data => data?.__typename !== undefined
const isListTypeGraph = data => isArray(data) && data.length > 0 && isTypeGraph(data[0])

const denormalizeObject = (graphData, schema, types) => {
  const typeObjKey = `${graphData.__typename}:${graphData.id}`
  const typeObj = types[typeObjKey]

  const result = {
    __typename: graphData.__typename
  }

  Object.entries(schema[graphData.__typename]).forEach(([name, val]) => {
    if (!typeObj) {
      console.log('err graph', name, typeObjKey, typeObj, types)
    }
    result[name] = typeObj?.[name]
  })

  if (result) {
    Object.entries(result).forEach(([name, val]) => {
      if (isListTypeGraph(val)) {
        result[name] = val.map(v => denormalizeGraph(v, schema, types))
      } else if (isTypeGraph(val)) {
        result[name] = denormalizeGraph(val, schema, types)
      }
    })
  }

  return result
}

const denormalizeGraph = (graphData, schema, types) => {
  if (isTypeGraph(graphData)) return denormalizeObject(graphData, schema, types)

  const result = {}
  graphData && Object.entries(graphData).forEach(([key, value]) => {
    // if (isListTypeGraph(val)) {
    //   const graphType = graphQuery[key]
    //   result[key] = denormalizeGraph(val, graphQuery, types)
    // }
    // else if (isTypeGraph(value)) {
    if (isTypeGraph(value)) {
      // const graphType = schema[key]
      result[key] = denormalizeObject(value, schema, types)
    }
  })
  return result
}

export const getDenormalizedGraph = createSelector(
  dependencySelector,
  getGraphData,
  (types, data) => {
    const graph = types ? denormalizeGraph(data.graph, data.schema, types) : null
    // const graph = data?.graph && denormalizeGraph(data.graph, data.graphQuery, types)
    const status = data?.status
    let updateKey = 0
    if (types) {
      Object.values(types).forEach(typ => {
        if (typ.__updateKey) updateKey += typ.__updateKey
      })
    }

    return {
      graph, status, schema: data?.schema, updateKey
    }
  }
)