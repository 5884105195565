import React from 'react'

// Vendor
import QRCode from "react-qr-code"

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Image from 'reactor-ui/components/Image'
import Badge from 'reactor-ui/components/Badge'
import Button from 'reactor-ui/components/Button'

import Enum from 'reactor-vera/apps/model/components/Enum'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useReactorAction from 'reactor/hooks/useReactorAction'
import { useNavigate } from 'reactor-vera/router'

import RfActionForm from 'reactor-form/components/RfActionForm'
import { RfFormField } from 'reactor-form/components/RfForm'

import { Routes, Route, useParams, Link } from 'reactor-vera/router'

import ProgramManagementHome, { ProgramManagementDetail } from './Program'

import GraphTable from 'reactor-vera/apps/data/components/GraphTable'


const UserMembership = props => {
  const result = useReactorQuery({
    fmMember: {
    }
  })

  const fmMember = result.graph?.fmMember
  if (!fmMember) return null
  console.log('fmMember', fmMember)
  return (
    <Flex sx={{
      flexDirection: ['column', null, null, 'row']
    }}>
      <Card sx={{
        width: ['100%', null, null, '200px'],
        mb: [4, null, null, 0],
        textAlign: 'center',
        flex: ['0 0 auto', null, null, '0 0 200px']
      }}>
        <Image src={fmMember.photo?.url} width={'150px'} borderRadius="full"
          boxSize="150px" sx={{
            mx: 'auto'
          }} />
        <Box sx={{
          textAlign: 'center',
          mt: 3
        }}>
          {fmMember.name} {fmMember.surname}
        </Box>

        <Box sx={{
          textAlign: 'center',
          mt: 3
        }}>
          <Badge mx={2} colorScheme={'green'} variant='solid' px={2} py={1}>{fmMember.program.name}</Badge>
        </Box>

        <Box sx={{
          textAlign: 'left',
          my: 3
        }}>
          <Box my={2}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'sm'
            }}>
              Telefon
            </Box>

            <Box>
              {fmMember.phone}
            </Box>
          </Box>

          <Box my={2}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'sm'
            }}>
              EPosta
            </Box>

            <Box>
              {fmMember.email}
            </Box>
          </Box>

          <Box my={2}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'sm'
            }}>
              TC
            </Box>

            <Box>
              {fmMember.govId}
            </Box>
          </Box>

          <Box my={2}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'sm'
            }}>
              Durum
            </Box>

            <Box>
              <Enum value={fmMember.status} />
            </Box>
          </Box>
{/*
          <Box my={2}>
            <Box sx={{
              fontWeight: 'bold',
              fontSize: 'sm'
            }}>
              Kredi
            </Box>

            <Box>
              {fmMember.credit}
            </Box>
          </Box> */}

          {fmMember.status === 'ACTIVE' && (
            <Button as={Link} to='payment' colorScheme='brand'>Kredi Ekle</Button>
          )}

          <Box>
            <Logout />
          </Box>
        </Box>
      </Card>

      <Routes>
        <Route path='payment' element={(
          <Card>
            <Payment credit fmMember={fmMember} />
          </Card>
        )} />
        <Route path='schedule/:rnxAsScheduleId/*' element={(
          <Box sx={{
            overflow: 'scroll'
          }}>
            {/* <Card sx={{
            flex: '1 1 0',
            mx: [0, null, null, 4],
            overflow: 'scroll'
          }}> */}
            <ProgramManagementDetail />
            {/* </Card> */}
          </Box>
        )} />
        <Route path='/' element={(
          <>
            {fmMember.status === 'PASSIVE' && (
              <Box sx={{
                mb: 6
              }}>
                <Card sx={{
                  flex: '1 1 0',
                  mx: [0, null, null, 4]
                }}><Payment fmMember={fmMember} onSuccess={result.refresh} /></Card>
              </Box>
            )}

            {fmMember.status === 'ACTIVE' && (
              <Box>
                <Box sx={{
                  flex: '1 1 0',
                  mx: [0, null, null, 4],
                  mb: 4
                }}>
                  <GraphTable
                    modelName='RnxResourceBalance'
                    headerText='Krediler'
                    // detailLink='program'
                    query={{
                      filters: {
                        owner_entity_key: fmMember.id,
                      },
                      columns: [
                        'name',
                        'quantity'
                      ]
                    }}
                  />
                </Box>

                <Box sx={{
                  flex: '1 1 0',
                  mx: [0, null, null, 4]
                }}>
                  <ProgramManagementHome />
                </Box>

                <Box sx={{
                  flex: '1 1 0',
                  mx: [0, null, null, 4],
                  my: 4
                }}>
                  <GraphTable
                    modelName='RnxPacAccessRecord'
                    // detailLink='program'
                    query={{
                      filters: {
                        rnx_contact_id: fmMember.contactId
                      }
                    }}
                  />
                </Box>

                <Card sx={{
                  flex: '1 1 0',
                  mx: [0, null, null, 4],
                  my: 4
                }}>
                  <Card.Header>
                    QR Kod
                  </Card.Header>
                  <Box as='a' sx={{
                    display: 'block',
                    my: 4,
                    color: 'brand.500'
                  }} href={`/qr/${fmMember.govId}`}>
                    QR Kod Link
                  </Box>
                  <QRCode value={JSON.stringify({
                    id: fmMember.govId
                  })} />
                </Card>
              </Box>
            )}
          </>
        )} />
      </Routes>
    </Flex>
  )
}

const Iframe = (props) => {
  let iframe_ref = null;
  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    iframe_ref = frame;
    let doc = frame.contentDocument;
    doc.open();
    doc.write(props.content);
    doc.close();
    frame.style.width = '100%';
    // frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };
  return (
    <iframe height={1000} src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML}
    />
  );
};

const Payment = ({
  fmMember,
  credit,
  onSuccess
}) => {
  const [status, setStatus] = React.useState()
  React.useEffect(() => {
    const handler = event => {
      if (!event.data) return
      let data
      try {
        data = JSON.parse(event.data)
      } catch (error) {
        return
      }
      if (!data) return
      console.log("dt", data)
      if (data.paymentResult === 'SUCCESS') {
        window.location.reload()
      } else {
        setStatus('FAILURE')
      }
    }

    window.addEventListener("message", handler)

    return () => window.removeEventListener("message", handler)
  }, [])
  const [html, sethtml] = React.useState(null)
  if (status === 'SUCCESS') {
    return (
      <Box>
        Yönlendiriliyorsunuz.
      </Box>
    )
  }
  if (status === 'FAILURE') {
    return (
      <Box>
        Ödemeniz başarısız oldu. Bankanız ile iletişime geçiniz.
      </Box>
    )
  }
  return (
    <Box>
      <Box mb={4}>
        {!credit && 'Üyeliğinizi aktif hale getirmek için ödeme yapmalısınız.'}
      </Box>

      {html && (
        <Box>
          Ödeme sayfası yükleniyor.
          <Iframe content={html} />
        </Box>
      )}

      {!html && <RfActionForm sx={{
        mt: 3
      }} name='Ödeme Formu' actionKey='FmBase.MakePayment' fields={[

      ]} displayAs='FORM' buttonText={'ödeme yap'} initialValues={{
        forCredit: credit
      }} onSuccess={(data) => {
        sethtml(data.html)
      }} sx={{
        mx: 2
      }} key='approve' fieldElements={[
        <RfFormField required key='cardOwner' name='cardOwner' label='Kart Sahibi' />,
        <RfFormField required key='cardNo' name='cardNo' label='Kart Numarası' />,
        <Flex sx={{
          mx: -3,
          flexDirection: ['column', null, null, 'row']
        }}>
          <RfFormField required sx={{
            flex: '1 1 0',
            mx: 3
          }} key='lastDateMonth' name='lastDateMonth' label='Son Kullanım Tarihi - Ay' />
          <RfFormField required sx={{
            flex: '1 1 0',
            mx: 3
          }} key='lastDateYear' name='lastDateYear' label='Son Kullanım Tarihi - Yıl' />
          <RfFormField required sx={{
            flex: '1 1 0',
            mx: 3
          }} key='ccv' name='ccv' kind='password' label='Güvenlik Numarası (CCV)' />
        </Flex>,
        <Box>
          <RfFormField required sx={{
            // flex: '1 1 0',
            // mx: 3
          }} key='resource' name='resource' kind='radio' label='Paket' options={{
            options: fmMember.resources?.map((res, dx) => {
              return ({
                label: `${res.name} - ${res.price}₺`,
                value: res.id
              })
            })
          }} />
        </Box>
      ]} />}
    </Box>
  )
}

import { useSelector, useDispatch } from 'react-redux'
import reactorActions from 'reactor/systems/reactor/actions'

const Logout = ({

}) => {
  const dispatchAction = useReactorAction('RcApp.LogoutRcUser')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <Button my={4} isFullWidth onClick={() => {
      dispatchAction(null, {
        success: () => {
          dispatch(reactorActions.reset())
        navigate('/')
        }
      })
    }}>Çıkış</Button>
  )
}

export default UserMembership