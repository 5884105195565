import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import isObject from 'lodash/isObject'
import Select from 'react-select'
import { useTheme } from '@emotion/react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import TextInput from 'reactor-ui/components/TextInput'
import SwitchInput from 'reactor-ui/components/SwitchInput'
import DateInput from 'reactor-ui/components/DateInput'
import SelectInput from 'reactor-ui/components/SelectInput'


const FilterInput = ({
  label,
  target,
  filterInstance,
  kind,
  schema,
  focus = true
}) => {
  const { locationFilters, updateLocationFilters } = filterInstance
  const [value, setValue] = React.useState(locationFilters[target] || '')

  const updateLocationFiltersDebounced = React.useCallback(debounce(updateLocationFilters, 500), [])

  React.useEffect(() => {
    return () => {
      const targetName = schema?.valueType === 'Object' ? `${target}.name` : target
      updateLocationFilters({ [targetName]: undefined })
    }
  }, [])

  const onChange = React.useCallback((e) => {
    const val = e?.target ? e.target.value : e
    setValue(val)
  }, [])

  React.useEffect(() => {
    const targetName = schema?.valueType === 'Object' ? `${target}.name` : target
    updateLocationFiltersDebounced({ [targetName]: value })
  }, [value])

  if (kind == 'String') {
    return <TextFilter focus={focus} onChange={onChange} value={value} label={label} />
  } else if (kind === 'Integer') {
    return <TextFilter kind='number' onChange={onChange} value={value} label={label} />
  } else if (kind === 'Option') {
    return <OptionFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (schema.valueType === 'Object') {
    return <TextFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (kind === 'Date' || kind == 'DateTime') {
    return <DateTimeFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else if (kind === 'Boolean') {
    return <BooleanFilter onChange={onChange} value={value} label={label} schema={schema} />
  } else {
    return null
  }
}

const TextFilter = ({
  onChange,
  value,
  label,
  kind,
  focus
}) => {
  const [textVal, setTextVal] = React.useState(value?.value || kind === 'number' ? null : '')
  const [comparisonVal, setComparisonVal] = React.useState(value?.comparison || null)
  const textOnChange = React.useCallback((e) => {
    const val = e?.target ? e.target.value : e
    setTextVal(val)
  }, [])
  const comparisonOnChange = React.useCallback((e) => {
    setComparisonVal(e.target.checked && 'EQUALS')
  })

  React.useEffect(() => {
    if (textVal) {
      const searchVal = comparisonVal ? ({
        value: textVal,
        comparison: comparisonVal
      }) : (
        textVal
      )

      onChange(searchVal)
    } else {
      onChange(null)
    }
  }, [textVal, comparisonVal])
  return (
    <>
      <TextInput label={label} type={kind} value={textVal} onChange={textOnChange} focus={focus} requiredLabel={false} />
      {kind != 'number' && <>Tam: <SwitchInput isChecked={comparisonVal} onChange={comparisonOnChange} /></>}
    </>
  )
}

const OptionFilter = ({
  onChange,
  value,
  label,
  schema
}) => {
  const { t } = useTranslation()
  const options = []

  schema?.meta?.options.forEach(opt => {
    options.push({
      value: opt,
      label: t(`enum.${opt.toLowerCase()}`)
    })
  })

  return (
    <SelectInput
      onChange={onChange}
      isMulti
      isClearable
      value={value}
      options={options}
      placeholder={label}
    />
  )
}

const DateTimeFilter = ({
  onChange,
  value,
  label,
  schema
}) => {
  const options = [
    {
      value: '@currentWeek',
      label: 'Bu Hafta'
    },
    {
      value: '@currentMonth',
      label: 'Bu Ay'
    },
    {
      value: '@currentYear',
      label: 'Bu Yıl'
    },
    {
      value: '@lastWeek',
      label: 'Geçen Hafta'
    },
    {
      value: '@lastMonth',
      label: 'Geçen Ay'
    },
    {
      value: '@lastYear',
      label: 'Geçen Yıl'
    },
    {
      value: '@last7days',
      label: 'Son 7 gün'
    },
    {
      value: '@last30days',
      label: 'Son 30 gün'
    }
  ]

  return (
    <>
      <SelectInput
        onChange={onChange}
        isClearable
        value={value}
        options={options}
        placeholder={label}
      />

      <DateInput label='Başlangıç' onChange={(val) => {
        let newVal
        if (isObject(value)) {
          newVal = {
            ...value,
            start: val
          }
        } else {
          newVal = {
            start: val
          }
        }
        onChange(newVal)
      }} value={value?.start} />
      <DateInput label='Bitiş' onChange={(val) => {
        let newVal
        if (isObject(value)) {
          newVal = {
            ...value,
            end: val
          }
        } else {
          newVal = {
            end: val
          }
        }
        onChange(newVal)
      }} value={value?.end} />
    </>
  )
}

const BooleanFilter = ({

}) => {
  const options = [
    {
      value: true,
      label: 'Evet'
    },
    {
      value: false,
      label: 'Hayır'
    }
  ]

  return (
    <SelectInput
      onChange={onChange}
      isClearable
      value={value}
      options={options}
      placeholder={label}
    />
  )
}

export default FilterInput