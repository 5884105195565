import AuthorizationManagement from './pages/AuthorizationManagement'
import AuthUserManagement from './pages/AuthUserManagement'


export default {
  name: 'RcAuthSimple',
  components: {
    'AuthorizationManagement': {
      component: AuthorizationManagement
    },
    'AuthUserManagement': {
      component: AuthUserManagement
    },
  }
}