import FmForms from './pages/FmForms'
import ProfileManagement from './pages/ProfileManagement'
import FmPaymentManagement from './pages/FmPaymentManagement'
import AccessPlanManagement from './pages/AccessPlanManagement'


export default {
  name: 'FmBase',
  components: {
    'FmForms': {
      component: FmForms
    },
    'ProfileManagement': {
      component: ProfileManagement
    },
    'FmPaymentManagement': {
      component: FmPaymentManagement
    },
    'AccessPlanManagement': {
      component: AccessPlanManagement
    },
  }
}