import React from 'react'

// Reactor
import { Route, Routes, Navigate } from 'reactor-vera/router'
import AuthenticationBase from 'reactor-vera/apps/authentication/pages/AuthenticationBase'
import useApp from 'reactor-vera/hooks/useApp'

import ReactorVeraErrorBoundary from './ReactorVeraErrorBoundary'
import ReactorVeraHome from './ReactorVeraHome'


const RunicVeraIndex = ({
  uiConfig,
  runicConfig,
  homeUrl = 'home',
  homeElement,
  children,
  routes
}) => {

  // const { isReady } = useApp({ uiConfig, runicConfig })
  // const status = useApp({ uiConfig, runicConfig })
  const status = true

  return (
    <React.Suspense fallback='loading'>
      <ReactorVeraErrorBoundary>
        {/* <RenoaVersionNotification /> */}
        {status && (
          <Routes>
            <Route path="auth/*" element={<AuthenticationBase />} />
            {homeElement ? (
              <Route path="/" element={homeElement} />
            ) : (
              <Route path="/" element={<Navigate to={homeUrl} />} />
            )}
            <Route path="*" element={<>{children || <ReactorVeraHome homeElement={homeElement}/>}</>} />
            {routes}
          </Routes>
        )}
      </ReactorVeraErrorBoundary>
    </React.Suspense>
  )
}

export default RunicVeraIndex
