import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Vendor
import * as yup from 'yup'
export * as yup from 'yup'

import { Form as ReactFinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { setIn, getIn } from 'final-form'
export { setIn, getIn } from 'final-form'

// Volcano
export { default as Button } from 'reactor-ui/components/Button'

export { default as RfFormField } from 'reactor-form/components/RfFormField'
export { useFieldArray } from 'react-final-form-arrays'
export { Field, useField, useFormState, useForm } from 'react-final-form'
import { useFormState, useForm } from 'react-final-form'

export { default as RfFormError } from './RfFormError'
export { default as useReactorActionForm } from '../hooks/useReactorActionForm'
export { default as useUrlForm } from '../hooks/useUrlForm'
import Alert from 'reactor-ui/components/Alert'

import useShowIf from '../hooks/useShowIf'

export const ReactorFormContext = React.createContext()

export const Form = ({
  validate,
  renderSuccess,
  initialValues,
  children,
  phase,
  handleSubmit,
  error,
  ctx,
  id
}) => {
  const formElement = (
    <ReactFinalForm
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      validate={validate}>
      {({ handleSubmit, form }) => (
        <form id={id} onSubmit={handleSubmit}>
          {renderSuccess && phase === 'DONE' && renderSuccess()}
          {!(renderSuccess && phase === 'DONE') && children}
        </form>
      )}
    </ReactFinalForm>
  )

  const ctxRef = React.useRef()
  if (!ctxRef.current && ctx) {
    ctxRef.current = {
      entityMap: {},
      ...ctx,
    }
  }

  if (ctx) {
    return (
      <ReactorFormContext.Provider value={ctxRef.current}>
        {formElement}
      </ReactorFormContext.Provider>
    )
  } else {
    return formElement
  }
}

export const FormStatus = ({
  notReadyMessage
}) => {
  const st = useFormState()

  if (st.hasValidationErrors && st.dirty) {
    return (
      <Alert status='info' sx={{
        my: 2
      }}>{notReadyMessage}</Alert>
    )
  }

  return null
}

export const RfFormRow = ({
  alignItems,
  children
}) => {
  return (
    <Flex sx={{
      flexWrap: 'wrap',
      mx: -2,
      alignItems: alignItems || 'center'
    }}>
      {React.Children.map(children, (c, dx) => {
        return (
          <Box key={dx} sx={{
            flex: '1 1 0',
            mx: 2
          }}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export const RfFormSectionHeader = ({
  children
}) => {
  return (
    <Box sx={{
      fontWeight: 'bold',
      my: 4
    }}>{children}</Box>
  )
}

export const RfShowIf = ({
  conditions,
  fieldNames,
  parentPath,
  children,
  sx
}) => {

  const result = useShowIf(conditions, fieldNames, parentPath)
  if (!result) return null

  if (sx) return (
    <Box sx={sx}>{children}</Box>
  )

  return children
}

export const RfValue = ({
  fieldNames,
  parentPath,
  children,
  sx
}) => {
  const formState = useFormState()
  const values = {}
  fieldNames.forEach(name => {
    const fullFieldName = parentPath ? `${parentPath}.${name}` : name
    const value = getIn(formState.values, fullFieldName)
    values[name] = value
  })

  if (sx) return (
    <Box sx={sx}>{children({values})}</Box>
  )

  return children({values})
}

export default Form

// export const useUrlForm = ({
//   url,
//   data,
//   onSuccess,
//   onError,
//   getData,
//   parseData,
//   ...props
// }) => {
//   const [phase, setPhase] = React.useState('FORM')
//   const [error, setError] = React.useState(null)
//   const [result, setResult] = React.useState([])
//   const [isSubmitting, setSubmitting] = React.useState(false)

//   const handleSubmit = React.useCallback(async (values, form, callback) => {
//     setSubmitting(true)
//     let formData = {...values, ...data}
//     if (getData) formData = {...formData, ...getData()}
//     if (parseData) formData = parseData(formData)
//     const response = await Axios.post(url, formData).catch(e => {
//       setPhase('ERROR')
//       setSubmitting(false)
//       setError(e)
//       onError?.(e)
//     })
//     if (response) {
//       setPhase('DONE')
//       setSubmitting(false)
//       setResult(response.data)
//       onSuccess?.(response.data)
//     }
//   }, [])

//   const reset = React.useCallback(() => {
//     setPhase('FORM')
//     setError(null)
//     setResult([])
//     setSubmitting(false)
//   }, [])

//   return ([{
//     ...props,
//     reset,
//     phase,
//     error,
//     handleSubmit
//   }, isSubmitting])
// }

// export const useRunicForm = (
//   actionCreator,
//   callbacks = {},
//   options = {}
// ) => {
//   const [phase, setPhase] = React.useState('FORM')
//   const [error, setError] = React.useState(null)
//   const [result, setResult] = React.useState([])
//   const [isSubmitting, setSubmitting] = React.useState(false)

//   const dispatch = useDispatch()

//   const handleSubmit = (data, options) => {
//     let actionData = data
//     let dataCallbacks = {}

//     if (callbacks.action) {
//       const [newActionData, newCallbacks] = callbacks.action(data, options)
//       actionData = newActionData
//       dataCallbacks = newCallbacks
//     }

//     let progressCallback
//     if (callbacks.progress || dataCallbacks.progress) progressCallback = (dt) => {
//       callbacks?.progress?.(dt)
//       dataCallbacks?.progress?.(dt)
//     }
//     delete actionData._client
//     setSubmitting(true)
//     setError(null)
//     return dispatch(actionCreator(actionData, {
//       success: (payload) => {
//         setPhase('DONE')
//         setError(null)
//         setResult(payload)
//         setSubmitting(false)
//         callbacks?.success?.(payload)
//         dataCallbacks?.success?.(payload)
//       },
//       error: (payload) => {
//         setPhase('ERROR')
//         setError(payload)
//         setResult(payload)
//         setSubmitting(false)
//         callbacks?.error?.(payload)
//         dataCallbacks?.error?.(payload)
//       },
//       progress: progressCallback
//     }))
//   }

//   return ([{
//     // ...props,
//     ...options,
//     phase,
//     error,
//     handleSubmit,
//     result
//   }, isSubmitting])
// }
