// Vendor
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import union from 'lodash/union'
import * as changeCase from 'change-case'

// Reactor
import { createReducer, resource, setDraft } from 'reactor/core/redux'
import reactorActions from './actions'
import { current } from 'immer'


const defaultState = {
  status: {},
  appStatus: 'WAITING',
  source: {},
  model: {},
  queries: {},
  resource: {},
  graph: {
    schema: {},
    data: {},
    types: {},
    queries: {},
  },
  config: {
    baseAPIUrl: null,
  },
  activeTenantId: null,
  tenant: {}
}

const baseReducer = createReducer(defaultState, {
  [reactorActions.setup]: (draft, payload) => {
    draft.config = {
      ...draft.config,
      ...payload.config
    }
    if (payload.rcTenantId) draft.activeTenantId = payload.rcTenantId
  },

  [reactorActions.reset]: (draft, payload) => {
    Object.entries(defaultState).forEach(([k, v]) => {
      if (k != 'config') draft[k] = v
    })
  },

  [reactorActions.activateTenant]: (draft, id) => {
    draft.activeTenantId = id
  },

  // [source('RcApp.State.status')]: {
  //   success: (draft, payload) => {
  //     draft.status = payload
  //     draft.appStatus = 'READY'
  //   },

  //   error: (draft, payload) => draft.appStatus = 'ERROR',
  //   // start: (draft, payload) => draft.appStatus = 'LOADING'
  // },

  // [source('RcApp.State.tenant')]: {
  //   success: (draft, payload) => {
  //     const tenantId = payload.rcTenant.id
  //     draft.tenant[tenantId] = {
  //       payload,
  //       status: 'READY'
  //     }
  //     draft.activeTenantId = tenantId
  //   },

  //   error: (draft, payload) => {
  //     const tenantId = payload.tenantId
  //     draft.tenant[tenantId] = {
  //       status: 'ERROR'
  //     }
  //     draft.activeTenantId = null
  //   }
  //   // start: (draft, payload) => draft.appStatus = 'LOADING'
  // },
})

const getOrCreateModelSource = (draft, name) => {
  if (!draft.model[name]) draft.model[name] = {
    view: {},
    list: {},
    entity: {},
  }

  return draft.model[name]
}

const resourceReducer = createReducer(defaultState, {
  '__default': (draft, payload, state, action) => {
    if (action?.meta?.kind === '@@reactor/resourceQuery') {
      const queryKey = action.meta.queryKey
      if (action?.meta?.status == 'SUCCESS') {
        // const { query, variables } = payload
        const status = {
          dt: action.meta.dt,
          status: 'READY',
          isFetching: false,
          queryKey,
          error: null,
          lastFetch: new Date()
        }

        draft.queries[queryKey] = {...draft.queries[queryKey], ...status}
        draft.resource[action.meta.query] = payload
    } else if (action?.meta?.status == 'START') {
        const status = {
          isFetching: true,
          status: 'FETCHING',
          dt: action.meta.dt,
          error: null,
          lastFetch: new Date()
        }
        draft.queries[queryKey] = {...draft.queries[queryKey], ...status}
      } else if (action?.meta?.status == 'ERROR') {
        const status = {
          isFetching: false,
          status: 'ERROR',
          dt: action.meta.dt,
          error: payload
        }
        draft.queries[queryKey] = {...draft.queries[queryKey], ...status}
      }
    }
  }
})

const normalizeObject = (obj, schema, types) => {
  const normalizedObj = {}
  const typeObj = {}

  Object.entries(obj).forEach(([valKey, valValue]) => {
    if (isTypeGraph(valValue)) {
      typeObj[valKey] = normalizeGraph(valValue, schema, types)
    } else if (isListTypeGraph(valValue)) {
      typeObj[valKey] = valValue.map(v => normalizeGraph(v, schema, types))
    } else {
      typeObj[valKey] = valValue
    }

    if (['id', '__typename', 'rc_entity_id', 'rc_tenant_id', '__fields', '__metadata'].includes(valKey)) {
      normalizedObj[valKey] = valValue
    }
  })

  const typeIdentifier = `${obj.__typename}:${typeObj.id}`
  if (!types[typeIdentifier]) types[typeIdentifier] = {...typeObj}
  else {
    types[typeIdentifier] = {
      ...types[typeIdentifier],
      ...typeObj
    }
  }

  return normalizedObj
}

// FIXME: use reactor/core/graph
const isTypeGraph = data => data?.__typename !== undefined
const isListTypeGraph = data => isArray(data) && data.length > 0 && isTypeGraph(data[0])

const normalizeGraph = (data, schema, types) => {
  const newData = {}

  if (isTypeGraph(data)) {
    return normalizeObject(data, schema, types)
  }

  Object.entries(data).forEach(([key, value]) => {
    if (isTypeGraph(value)) {
      newData[key] = normalizeObject(value, schema, types)
    } else {
      newData[key] = value
    }
  })
  return newData
}

const reactorQueryReducer = createReducer(defaultState, {
  '__default': (draft, payload, state, action) => {
    if (action?.meta?.kind === '@@reactor/reactorQuery') {
      const queryKey = action.meta.queryKey
      if (action?.meta?.status == 'SUCCESS') {
        const { data, schema } = payload

        const status = {
          dt: action.meta.dt,
          status: 'READY',
          isFetching: false,
          queryKey,
          error: null
        }

        // console.log('p', state.graph.schema, schema, rMerge(state.graph.schema, schema, {'arrayStrategy': 'MERGE'}))
        if (schema) draft.graph.schema = rMerge(state.graph.schema, schema, {'arrayStrategy': 'MERGE'})

        const types = {}
        const normalized = normalizeGraph(data, schema, types)
        const dependencies = Object.keys(types).map(x => x)

        let mergedSchema = draft.graph.data?.[queryKey]?.schema
        if (schema) {
          const oldSchema = draft.graph.data[queryKey].schema
          mergedSchema = rMerge(oldSchema, schema, {'arrayStrategy': 'MERGE'})
        }

        draft.graph.types = rMerge(state.graph.types, types)
        draft.graph.data[queryKey] = { graph: normalized, status, dependencies, schema: mergedSchema }
        if (__DEV__) {
          console.log(current(draft).graph)
        }
      } else if (action?.meta?.status == 'START') {
        const status = {
          isFetching: true,
          status: 'FETCHING',
          dt: action.meta.dt,
          error: null,
          queryKey,
          lastFetch: new Date()
        }
        draft.graph.data[queryKey] = {...draft.graph.data[queryKey], status}
      } else if (action?.meta?.status == 'ERROR') {
        const status = {
          isFetching: false,
          status: 'ERROR',
          dt: action.meta.dt,
          error: payload,
          queryKey,
        }
        draft.graph.data[queryKey] = {...draft.graph.data[queryKey], status}
      }
    } else if (action?.meta?.kind === 'RCR_ACTION') {
      if (action?.meta?.status == 'SUCCESS') {
        if (payload?.__type === 'ReactorResult') {

          payload?.created?.forEach(created => {
            const typename = changeCase.camel(created.model)
            const listNameToCheck = `${typename}List:`

            Object.entries(draft.graph.types).forEach(([name, data]) => {
              // console.log(name, listNameToCheck, name.includes(listNameToCheck))
              if (name.includes(listNameToCheck)) {
                if (!data.__updateKey) data.__updateKey = 0
                data.__updateKey += 1
              }
            })
          })

          payload?.updated?.forEach(updated => {
            const typename = changeCase.camel(updated.model)
            const nameToCheck = `${typename}:${updated.id}`

            Object.entries(draft.graph.types).forEach(([name, data]) => {
              if (name === nameToCheck) {
                if (!data.__updateKey) data.__updateKey = 0
                data.__updateKey += 1
              }
            })
          })

          payload?.deleted?.forEach(deleted => {
            const typename = changeCase.camel(deleted.model)
            const listNameToCheck = `${typename}List:`

            Object.entries(draft.graph.types).forEach(([name, data]) => {
              if (name.includes(listNameToCheck)) {
                if (!data.__updateKey) data.__updateKey = 0
                data.__updateKey += 1
              }
            })
          })

        }
      }
    }
  }
})

const rMerge = (existing, incoming, opts) => {
  const merged = { ...existing }

  Object.keys(incoming).forEach(incomingKey => {
    const incomingVar = incoming[incomingKey]
    const existingVar = merged[incomingKey]

    if (incomingVar === existingVar) {
      return
    }

    if (existingVar == null || existingVar == undefined) {
      merged[incomingKey] = incomingVar
      return
    }

    // if (incomingVar == null || incomingVar == undefined) {
    //   merged[incomingKey] = incomingVar
    //   return
    // }

    if (incomingVar == null) {
      merged[incomingKey] = incomingVar
      return
    } else if (existingVar && incomingVar == undefined) { // For partial updates.
      return
    }

    // Because isObject returns true for arrays, we need to check isArray first.
    const isArrIncomingVar = isArray(incomingVar)
    const isArrExistingVar = isArray(existingVar)

    if (isArrIncomingVar && isArrExistingVar) {
      const arrayStrategy = opts?.arrayStrategy || 'REPLACE'

      if (arrayStrategy === 'MERGE') {
        merged[incomingKey] = union(incomingVar, existingVar)
        return
      } else if (arrayStrategy === 'REPLACE') {
        merged[incomingKey] = incomingVar
        return
      }

      // merged[incomingKey] = incomingVar.concat(existingVar)
      // return

      // // FIXME: remove the redundant clause if it's not needed when stabilized.
      // if (incomingVar.length == 0) {
      //   merged[incomingKey] = incomingVar
      //   return
      // } else {
      //   merged[incomingKey] = incomingVar
      //   return
      // }
    }

    const isObjIncomingVar = isObject(incomingVar)
    const isObjExistingVar = isObject(existingVar)

    // FIXME: check if working for partial updates
    if (isObjExistingVar && isObjIncomingVar) {
      merged[incomingKey] = rMerge(existingVar, incomingVar, opts)
      return
    }

    merged[incomingKey] = incomingVar

  })

  return merged
}

const finalReducer = (state = defaultState, action) => {
  const baseState = baseReducer(state, action)
  const sourceState = resourceReducer(baseState, action)
  const finalState = reactorQueryReducer(sourceState, action)
  return finalState
}

export default finalReducer