import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Button from 'reactor-ui/components/Button'

// Reactor
import { Link } from 'reactor-vera/router'


const ChannelSelector = props => {
  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      bg: 'brand.500'
    }}>
      <Card sx={{
        position: 'absolute',
        top: '20%',
        left: ['3%', null, null, '30%'],
        width: ['94%', null, null, '40%']
      }}>
        <Button as={Link} to='/channel/admin' isFullWidth>
          Yönetici Girişi
        </Button>

        <Button as={Link} to='/channel/user' isFullWidth mt={4}>
          Kullanıcı Girişi
        </Button>
      </Card>
    </Box>
  )
}

export default ChannelSelector