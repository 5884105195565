import FormManagement from './pages/FormManagement'


export default {
  name: 'RcForm',
  components: {
    'FormManagement': {
      component: FormManagement
    },
  }
}