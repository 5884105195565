import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

const defaultOptions = {
  kind: 'RCR_ACTION'
}

const useReactorAction = (identifier, options) => {
  const dispatch = useDispatch()
  const action = React.useCallback((payload, callbacks) => ({
    type: `@@reactor/action/${identifier}`,
    meta: {
      status: 'START',
      kind: options?.kind || 'RCR_ACTION',
      identifier: identifier,
    },
    payload,
    callbacks,
  }), [identifier])

  action.actionType = identifier

  return React.useCallback((payload, callbacks) => {
    dispatch(action(payload, callbacks))
  }, [action])
}

export default useReactorAction