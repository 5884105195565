import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import ReactorAction from 'reactor-vera/apps/data/components/ReactorAction'


const ReactorServerAction = ({
  identifier, initialValues, options = {}, onSuccess, anchor, renderer
}) => {
  const result = useReactorQuery({
    'reactorActionList': {
      actions: {
        [identifier]: options
      }
    }
  })

  const action = result.graph?.reactorActionList.actions?.[identifier]
  if (!action) return null

  if (renderer) return renderer({action})

  return (
    <ReactorAction
      onSuccess={onSuccess}
      anchor={anchor}
      action={action}
      initialValues={initialValues}
       />
  )
}

export default ReactorServerAction