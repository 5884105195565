import RnxAccessManagement from './pages/RnxAccessManagement'
import RnxAccessReports from './pages/RnxAccessReports'
import RnxAccessPointManagement from './pages/RnxAccessPointManagement'

export default {
  name: 'RnxPac',
  components: {
    'RnxAccessManagement': {
      component: RnxAccessManagement
    },
    'RnxAccessReports': {
      component: RnxAccessReports
    },
    'RnxAccessPointManagement': {
      component: RnxAccessPointManagement
    },
  }
}