// Vendor
import merge from 'lodash/merge'

// Reactor
import { createReducer, resource, setDraft } from 'reactor/core/redux'

import reactorVeraActions from './actions'

const defaultState = {
  zones: {},
  units: {},
  defaultZone: null,
  components: {},
  activeZoneName: null,
  activeDomainName: null,
  activeUnitName: null,
  activeModelData: null,
  activeZoneParams: null,
  directory: {},
  context: {},
  breadcrumbs: [],

  config: {
    layout: {
      componentKey: 'SidebarLayout',
      contentComponentKey: 'SidebarLayoutContent',
      sidebar: {
        extended: true
      }
    }
  },
}

export default createReducer(defaultState, {
  [resource('rcApp.state.tenant_status')]: {
    success: (draft, payload) => {
      if (!payload?.ui) return
      const { defaultZone, units, zones } = payload.ui

      draft.zones = zones
      // draft.units = units
      draft.defaultZone = defaultZone
    },

    // error: (draft, payload) => {
    //   const tenantId = payload.tenantId
    //   draft.tenant[tenantId] = {
    //     status: 'ERROR'
    //   }
    //   draft.activeTenantId = null
    // }
    // start: (draft, payload) => draft.appStatus = 'LOADING'
  },

  [reactorVeraActions.rncActivateZone]: (draft, { zoneName, zoneParams }, state) => {
    draft.activeZoneName = zoneName
    draft.activeZoneParams = zoneParams
  },

  [reactorVeraActions.rncActivateDomain]: (draft, { domainName }, state) => draft.activeDomainName = domainName,

  [reactorVeraActions.rncActivateUnit]: (draft, { unitName }, state) => draft.activeUnitName = unitName,

  [reactorVeraActions.updateConfig]: (draft, payload) => draft.config = merge(draft.config, payload),

  [reactorVeraActions.addBreadcrumb]: (draft, payload) => draft.breadcrumbs.push(payload),

  [reactorVeraActions.removeBreadcrumb]: (draft, {key}) => {
    const dx = draft.breadcrumbs.findIndex(x => x.key === key)
    // if (dx != -1) draft.breadcrumbs = draft.breadcrumbs.filter((_, idx) => idx < dx)
    if (dx != -1) draft.breadcrumbs = draft.breadcrumbs.filter((_, idx) => idx != dx)
  },

  // [reactorVeraActions.RNC_ACTIVATE_DETAIL]: (draft, payload) => draft.activeModelData = payload,
  // [reactorVeraActions.RNC_DEACTIVATE_DETAIL]: (draft) => draft.activeModelData = null,

  // [reactorVeraActions.RNC_ACTIVATE_SUB_DETAIL]: (draft, payload, state) => {
  //   if (state.activeModelData) draft.activeModelData = {...state.activeModelData, subDetail: payload}
  // },
  // [reactorVeraActions.RNC_DEACTIVATE_SUB_DETAIL]: (draft, _, state) => {
  //   if (state.activeModelData && state.activeModelData.subDetail) delete draft.activeModelData.subDetail
  // },

  // [reactorVeraActions.setupUi]: (draft, { config }, state) => draft.config = config,

  // [reactorVeraActions.RNC_REGISTER_COMPONENTS]: (draft, { components }, state) => draft.components = components,

  // [reactorVeraActions.RNC_MODIFY_DIRECTORY]: (draft, payload, state) => draft.directory = {...draft.directory, ...payload},

  // [reactorVeraActions.RNC_MODIFY_CONTEXT]: (draft, payload, state) => draft.context = {...draft.context, ...payload},
})
