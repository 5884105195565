import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'
import Icon from 'reactor-ui/components/Icon'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'

import { Link } from 'reactor-vera/router'
import RfActionForm from 'reactor-form/components/RfActionForm'

import UserProgram from 'elements/rnx_activity_schedule/rnxActivitySchedule/components/UserProgram'



const rnxAsScheduleManagement = props => {
  const { t } = useTranslation()
  console.log('uu')
  return (
    <Routes>
      <Route path='/' element={<ProgramManagementHome />} />
      <Route path='schedule/:rnxAsScheduleId/*' element={<ProgramManagementDetail />} />
    </Routes>
  )
}

const ProgramManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <GraphTable
        modelName='RnxAsSchedule'
        detailLink='schedule'
        showEntityActions={false}
        showModelActions={false}
      />
    </>
  )
}

export const ProgramManagementDetail = ({

}) => {
  console.log('kk')
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'rnxAsSchedule': {
      filters: {
        id: params.rnxAsScheduleId
      },
      fields: [
        'name', 'activeRnxAsScheduleTemplate', 'scheduleOptions'
      ]
    },
  })

  useBreadcrumb(`rnxAsScheduleDetail:${result.graph?.rnxAsSchedule.id}`, result.graph?.rnxAsSchedule.name)

  const rnxAsSchedule = result.graph?.rnxAsSchedule
  const meta = result.graph?.rnxAsScheduleMeta

  if (!rnxAsSchedule) return null

  return (
    <>
      <UserProgram rnxAsSchedule={rnxAsSchedule} />
    </>
  )
}


const ActivityProgram = ({
  rnxAsSchedule
}) => {
  const result = useReactorQuery({
    'rnxAsActivityList': {
      filters: {
        '@dt': {
          field: 'datetime_start',
          value: 'currentWeek'
        }
      },
      order_by: 'datetime_start|asc',
      itemFields: [
        'name', 'datetimeStart', 'datetimeFinish', { rnxAsActivityKind: { fields: ['name', 'limit', 'color'] } }
      ]
    },
    'fmMember': {
      fields: [
        'reservations'
      ]
    }
  })
  const rnxAsActivityList = result.graph?.rnxAsActivityList
  console.log('rnxAsActivityList', rnxAsActivityList)
  if (!rnxAsActivityList) return null
  console.log('s---', result.graph?.fmMember)


  return <ActivityProgramContent rnxAsSchedule={rnxAsSchedule} result={result} rnxAsActivityList={rnxAsActivityList} />
}

const ActivityProgramContent = ({
  rnxAsSchedule,
  result,
  rnxAsActivityList
}) => {
  const today = dayjs().startOf('today')
  const start = today.hour(9).minute(0)
  const end = today.hour(23).minute(59)

  const interval = 15
  const length = 60

  const hours = []

  const activities = React.useMemo(() => {
    return rnxAsActivityList.items?.map(a => {
      const result = {
        ...a,
        start: dayjs(a.datetimeStart).add(3, 'hours'),
        finish: dayjs(a.datetimeFinish).add(3, 'hours')
      }
      let dy = result.start.day() + 3
      if (dy > 6) dy -= 7
      console.log('ct', result.start.format('DD.MM.YYYY HH:mm'), result.start.day(), dy)
      return result
    })
  }, [rnxAsActivityList])
  // console.log(rnxAsActivityList)

  const reservations = React.useMemo(() => {
    return result.graph?.fmMember.reservations?.map(a => {
      console.log('a', a)
      const rs = {
        ...a,
        start: dayjs(a.datetime_start).add(3, 'hours'),
        finish: dayjs(a.datetime_finish).add(3, 'hours')
      }
      let dy = rs.start.day()
      // let dy = rs.start.day() + 3
      // if (dy > 6) dy -= 7
      console.log('res', rs.start.format('DD.MM.YYYY HH:mm'), rs.start.day(), dy)
      return rs
    })
  }, [result.graph?.fmMember])

  let a = 0

  while (hours.length == 0 || (hours[hours.length - 1].end.add(interval + length, 'minute') < end)) {
    let prevEnd
    if (hours.length) {
      prevEnd = hours[hours.length - 1].end
    } else {
      prevEnd = start.subtract(interval, 'minute')
    }

    const nextStart = prevEnd.add(interval, 'minute')
    const nextEnd = nextStart.add(length, 'minute')

    // const dayActivities = activities.filter(x => x.start.day() == nextStart.day())
    // if (dayActivities) console.log('hour', nextStart.format('HH:mm'), dayActivities)
    // if (a == 0) {
    //   console.log(nextStart.format('DD.MM.YYYY HH:mm'), nextStart.day())
    //   console.log(activities[1].start.format('DD.MM.YYYY HH:mm'), activities[0].start.day())
    //   console.log(nextStart.day() == activities[0].start.day())
    //   a = 1
    // }
    //
    const next = {
      start: nextStart,
      end: nextEnd,
      id: nextStart,
      // activities: hourActivities,
    }
    activities.forEach(a => {
      // console.log(a.start.hour(), nextStart.hour())
      // console.log('st', a.start.day(), a.start.hour())
      if (a.start.hour() == nextStart.hour()) {
        let dy = a.start.day() + 3
        if (dy > 6) dy -= 7
        next[dy] = a
      }
    })

    reservations.forEach(a => {
      // console.log(a.start.hour(), nextStart.hour())
      // console.log('st', a.start.day(), a.start.hour())
      if (a.start.hour() == nextStart.hour()) {
        let dy = a.start.day() + 3
        if (dy > 6) dy -= 7
        next[dy] = a
      }
    })
    hours.push(next)
  }
  console.log(hours.length, hours)

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Saatler',
        id: 'hours',
        // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id }} />,
        // alignLast: true,
      },
      {
        Header: 'Pazartesi',
        id: 0,
        accessor: '0'
      },
      {
        Header: 'Salı',
        id: 1,
        accessor: '1',
      },
      {
        Header: 'Çarşamba',
        id: 2,
        accessor: '2',
      },
      {
        Header: 'Perşembe',
        id: 3,
        accessor: '3',
      },
      {
        Header: 'Cuma',
        id: 4,
        accessor: '4',
      },
      {
        Header: 'Cumartesi',
        id: 5,
        accessor: '5',
      },
      {
        Header: 'Pazar',
        id: 6,
        accessor: '6',
      },
    ]
  }, [])

  // const tableRows = []
  const tableRows = hours

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
      <Thead>
        {headerGroups.map(headerGroup => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, dx) => {
                const { key, ...headerProps } = column.getHeaderProps()
                return (
                  <Th>
                    {column.Header}
                  </Th>
                )
                return (
                  <GraphTableColumnHeader isLast={dx === columnCount - 1} key={key} headerProps={headerProps} column={column} />
                )
              })}
            </Tr>
          )
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, dx) => {
          prepareRow(row)
          // console.log(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell, cellDx) => {
                const { key, cellProps } = cell.getCellProps()
                if (cell.column.id === 'hours') {
                  return (
                    <Td key={key}>
                      {row.original.start.format('HH:mm')} - {row.original.end.format('HH:mm')}
                    </Td>
                  )
                }
                // rnxAsActivityList
                const activities = null
                return (
                  <Td>
                    {cell.value ? (
                      <Box>
                        {cell.value.name ? (
                          <Box sx={{
                            textAlign: 'center',
                            p: 3,
                            color: cell.value?.rnxAsActivityKind?.color
                          }}>
                            {cell.value.name}
                            {/* {console.log('cell', cell)} */}

                            <RfActionForm header={<Icon name='plus' />} actionKey='FmBase.MakeReservation' fields={[

                            ]} buttonText={'rezervasyon yap'} initialValues={{
                              id: cell.value.id
                            }} onSuccess={() => result.refresh()} />
                          </Box>
                        ) : (
                          <RfActionForm buttonText={'Iptal Et'} header={<Icon name='circle-remove' />} actionKey='FmBase.CancelReservation' fields={[

                          ]} initialValues={{
                            id: cell.value.id
                          }} onSuccess={() => result.refresh()} />
                        )}
                      </Box>
                    ) : (
                      // <RfActionForm name='ekle' actionKey='RnxActivitySchedule.CreateAsScheduleActivity' fields={[
                      //   {
                      //     name: 'name',
                      //     label: 'isim'
                      //   },

                      //   {
                      //     name: 'rnxAsActivityKind',
                      //     label: 'Tür',
                      //     kind: 'entity'
                      //   }
                      // ]} initialValues={{
                      //   start: row.original.start.day(cell.column.id + 4),
                      //   end: row.original.end.day(cell.column.id + 4),
                      //   rnx_as_schedule_id: rnxAsSchedule.id
                      // }} onSuccess={() => result.refresh()} />
                      null
                    )}
                  </Td>
                )
                return (<Td />)
                return <GraphTableCell isLast={cellDx === columnCount - 1} key={key} cellProps={cellProps} cell={cell} />
              })}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

// const ScheduleActivities = ({})

export default rnxAsScheduleManagement