import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'

import TextInput from 'reactor-ui/components/TextInput'

import processors from '../processors'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfTextField = ({
  name,
  type,
  multiLine,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value)
  })

  field.processors?.forEach(processor => {
    processors[processor.name](processor, field, input, meta)
  })

  // if (multiLine) {
  //   return (
  //     <Box as='textarea' {...input} sx={{
  //       width: '100%',
  //       px: 2,
  //       py: 2,
  //       ...field?.sx
  //     }}/>
  //   )
  // }

  return (
    <Box sx={{
      width: '100%'
    }}>
      <TextInput
        labelSx={{
          textTransform: 'capitalize'
        }}
        tabIndex={field.tabIndex}
        multiline={multiLine}
        requiredLabel={field.requiredLabel}
        placeholder={field.placeholder}
        type={type || field.kind}
        required={field?.required}
        label={t(field?.label)}
        wrapperSx={{
          zIndex: 1,
        }}
        sx={{
          width: '100%',
          ...field.sx
        }}
        {...input}
        step={field.step}
        autoFocus={field.autoFocus}
        disabled={field.disabled}
      />
      <RfFieldAlert meta={meta} sx={{
        mt: -2,
        zIndex: 0,
        pt: 4,
        pb: 3,
      }}/>
      <RfFieldDescription field={field}/>
    </Box>
  )
}

export default RfTextField