import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import reactorActions from 'reactor/systems/reactor/actions'

// Reactor
import { Routes, useParams, Route, useHref } from 'reactor-vera/router'

import PlexUiZoneRouter from 'reactor-vera/apps/ui/pages/PlexUiZoneRouter'
import ReactorVeraRouteManager from 'reactor-vera/apps/ui/components/ReactorVeraRouteManager'

import useResourceQuery from 'reactor/hooks/useResourceQuery'


const ReactorTenantIndex = props => {
  const params = useParams()
  const zones = useSelector(state => state.reactorVera?.zones)

  const tenantStatus = useResourceQuery('rcApp.state.tenant_status', {'@rcTenantId': params.rcTenantId})
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(reactorActions.activateTenant(params.rcTenantId))
  }, [params.rcTenantId])

  // const tenantData = useTenant(params.rcTenantId)
  // const isReady = tenantData?.status === 'READY'

  const href = useHref('')

  if (!tenantStatus?.rcTenant) return null

  return (
    <>
      <ReactorVeraRouteManager tenantId={tenantStatus?.rcTenant.id} />
      <Routes>
        {Object.values(zones).map((zone, dx) => (
          <Route key={dx} path={`${zone.path}/*`} element={<PlexUiZoneRouter zone={zone} baseUrl={href} />}/>
        ))}
      </Routes>
    </>
  )
}

export default ReactorTenantIndex
