import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import useReactor from 'reactor/hooks/useReactor'

import { Route, Routes, useParams } from 'reactor-vera/router'

import runicVeraActions from 'reactor-vera/systems/reactor-vera/actions'

import PlexUiDomainRouter from './PlexUiDomainRouter'


const PlexuiZoneRouter = ({
  zone,
  baseUrl
}) => {
  const reactor = useReactor()
  const dispatch = useDispatch()
  const params = useParams()

  const layoutConfig = useSelector(state => state.reactorVera.config.layout)

  React.useEffect(() => {
    dispatch(runicVeraActions.rncActivateZone({ zoneName: zone.name, zoneParams: params }))
  }, [zone])

  if (zone.handler) {
    const ZoneHandler = reactor.directory.reactorVera?.zoneHandlers?.[zone.handler]
    if (ZoneHandler) return <ZoneHandler baseUrl={baseUrl} zone={zone} zoneParams={params}/>
  }

  const LayoutComponent = reactor.directory.reactorVera?.components?.[layoutConfig.componentKey]

  if (!LayoutComponent) {
    // FIXME: add message
    return null
  }

  const zoneContent = (
    <Routes>
      {Object.values(zone.domains).map((domain, dx) => {
        const path = `${domain.path}/*`
        return (
          <Route key={dx} path={path} element={<PlexUiDomainRouter zone={zone} domain={domain} />}/>
        )
      })}
    </Routes>
  )

  return <LayoutComponent baseUrl={baseUrl} zone={zone} zoneContent={zoneContent}/>
}

export default React.memo(PlexuiZoneRouter)
