import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Alert from 'reactor-ui/components/Alert'
import Button from 'reactor-ui/components/Button'
import Portal from 'reactor-ui/components/Portal'
import { HStack } from 'reactor-ui/components/Stack'
import { Menu, MenuButton, MenuList, MenuItem, useDisclosure } from 'reactor-ui/components/menu'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import ReactorAction from './ReactorAction'

import QueryLoading from 'reactor-vera/apps/ui/components/QueryLoading'


const ActionGroup = ({
  name, contexts, kinds, ctx, renderer, callbacks, ready = true
}) => {
  const { t } = useTranslation()
  const result = useReactorQuery({
    reactorActionGroup: {
      name,
      contexts,
      kinds,
      ctx
    }
  }, {
    ready: ready
  })

  if (result?.status?.status === 'ERROR') {
    return (
      <Alert sx={{
        width: 'auto',
        mx: 3,
        borderRadius: 4
      }}>
        {t('text.action_fetch_error')}

        <Button ml={3} size='sm' onClick={result.refresh} sx={{
          textTransform: 'capitalize',
        }}>{t('op.refresh')}</Button>
      </Alert>
    )
  }

  const actions = result?.graph?.reactorActionGroup?.actions

  let content
  if (actions) {
    if (renderer) {
      content = renderer(actions, callbacks)
    } else {
      content = (
        <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}>
          {actions.map((action, dx) => <ReactorAction onSuccess={data => {
            callbacks?.onSuccess?.(data)
            result.refresh()
          }} action={action} key={dx} />)}
        </HStack>
      )
    }
  }

  return (
    <QueryLoading result={result}>
      {content}
    </QueryLoading>
  )

}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "reactor-ui/components/Modal"


export const ActionGroupMenu = ({
  sx,
  callbacks,
  anchor,
  ...props
}) => {
  const { t } = useTranslation()
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const [content, setContent] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)

  let rendered
  if (content) rendered = (
    // <MenuList>
    <Box p={3}>
      {content}
    </Box>
    // </MenuList>
  )
  else {
    rendered = (
      <ActionGroup ready={isOpen} renderer={actions => {
        return (
          <div>
            {actions.map((action, dx) => {
              if (action.meta?.ui?.displayAs === 'MENU') {
                return (
                  <MenuItem sx={{
                    textTransform: 'capitalize'
                  }} key={dx} onClick={() => setContent(
                    <ReactorAction onSuccess={callbacks?.onSuccess} onClose={() => {
                      // onClose()
                      setContent(null)
                    }} displayAs='FORM' action={action} />
                  )}>
                    {t(action.meta.ui.name)}
                  </MenuItem>
                )
              }

              return (
                <ReactorAction key={dx} onClose={() => {
                  // onClose()
                  setContent(null)
                }} anchorKind={'MENUITEM'} action={action} />
              )
            })}
            {actions?.length === 0 && (
              <Box textAlign='center' sx={{ fontSize: 'sm' }}>
                Aksiyon yok.
              </Box>
            )}
          </div>
        )
      }} displayAs={'menuItem'} {...props} />
    )
  }

  // return (
  //   <Popover
  //   onOpen={() => setIsOpen(true)}
  //   onClose={() => setIsOpen(false)}
  //   sx={{
  //     textAlign: 'left'
  //   }}
  //   >
  //     <PopoverTrigger>
  //       <Button><Icon name='more' size={'12px'} color='brand.500' /></Button>
  //     </PopoverTrigger>
  //     <PopoverContent>
  //       <PopoverArrow />
  //       <PopoverCloseButton />
  //       <PopoverHeader>Confirmation!</PopoverHeader>
  //       <PopoverBody>
  //         {/* {isOpen && rendered} */}
  //         {rendered}
  //       </PopoverBody>
  //     </PopoverContent>
  //   </Popover>
  // )

  let button
  if (anchor) {
    button = (
      <MenuButton sx={sx}
    // onClick={onOpen}
    // rightIcon={<ChevronDownIcon />}
    >
      {anchor}
    </MenuButton>
    )
  } else {
    button = (
      <MenuButton as={Button} size='xs' sx={sx}
      // onClick={onOpen}
      // rightIcon={<ChevronDownIcon />}
      >
        <Icon name='more' size={'12px'} color='brand.500' />
      </MenuButton>
    )
  }

  return (
    <Menu
      // isLazy
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false)
        setContent(null)
      }}
      // isOpen={isOpen}
      // onClose={() => {
      //   onClose()
      //   // setContent(null)
      // }}
      closeOnSelect={false}
      closeOnBlur={!content}
    >
      {({ isOpen }) => (
        <>
          {button}
          {/* {isOpen && rendered} */}
          <Portal>
            <MenuList>
              {rendered}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}

export default ActionGroup