import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// ReactorUI
import { Box, Flex } from 'reactor-ui'
import { Checkbox, CheckboxGroup } from 'reactor-ui/components/Checkbox'
import { HStack } from 'reactor-ui/components/Stack'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfCheckboxGroupField = ({
  name,
  field,
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })

  return (
    <>
      <Box mb={1} fontSize='sm'>
      {field.label}
      </Box>
      <CheckboxGroup onChange={input.onChange} colorScheme="brand" defaultValue={input.value}>
        <HStack wrap='wrap' justify='space-between' shouldWrapChildren spacing={2}>
          {field.options.options.map((option, dx) => {
            return (
              <Checkbox my={1} key={dx} value={option.value}>{t(option.label)}</Checkbox>
            )
          })}
        </HStack>
      </CheckboxGroup>
      <RfFieldAlert meta={meta}/>
      <RfFieldDescription field={field} position='default'/>
    </>
  )
}

export default RfCheckboxGroupField