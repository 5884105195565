import RnxDeviceManagement from './pages/RnxDeviceManagement'
import RnxDeviceAppManagement from './pages/RnxDeviceAppManagement'
import DeviceRegister from './pages/DeviceRegister'


export default {
  name: 'RnxDevice',
  components: {
    'RnxDeviceManagement': {
      component: RnxDeviceManagement
    },
    'RnxDeviceAppManagement': {
      component: RnxDeviceAppManagement
    },
    'DeviceRegister': {
      component: DeviceRegister
    },
  }
}