import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { useNavigate, useParams, Link } from 'reactor-vera/router'
import useReactor from 'reactor/hooks/useReactor'
import useReactorAction from 'reactor/hooks/useReactorAction'

// Reactor Ui
import { Flex, Box } from "reactor-ui"
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'


const AuthenticationReset = props => {
  const [phase, setPhase] = React.useState('CHECK')
  // const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const dispatchAction = useReactorAction('RcApp.VerifyResetToken')

  React.useEffect(() => {
    dispatchAction({ token: params.token }, {
      success: (payload) => setPhase('FORM'),
      error: (payload) => setPhase('ERROR')
    })
  }, [])

  if (phase == 'FORM') {
    return (<AuthenticationResetForm token={params.token} />)
  } else {
    let message
    if (phase == 'CHECK') message = 'Kontrol ediliyor.'
    else if (phase == 'ERROR') message = 'Bu istek geçersiz veya süresi dolmuş.'

    return (
      <Flex alignItems='center' justifyContent='center'>
        <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
          <Card>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifre Sıfırla
            </Box>
            {message}
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../' tabIndex={5}>
                Girişe geri dön
              </Link>
            </Flex>
          </Card>
        </Box>
      </Flex>
    )
  }

}

const AuthenticationResetForm = ({
  token
}) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  const [formProps, isSubmitting] = useReactorActionForm(
    'RcApp.ResetPassword',
    {
      initialValues: {
        token
      },
      // onSuccess: () => window.location.reload()
    }
  )

  if (formProps.phase === 'SUCCESS') {
    return (
      <Flex alignItems='center' justifyContent='center'>
        <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
          <Card>
            <Form {...formProps}>
              <Box sx={{
                fontWeight: 'bold',
                mb: 3
              }}>
                Şifremi Unuttum
              </Box>
              Şifren başarıyla değişti. <Link to='../'>Buradan</Link> giriş yapabilirsin.
              <Flex sx={{
                flexDirection: 'row-reverse',
                mt: 3
              }}>
                <Link to='../' tabIndex={5}>
                  Girişe geri dön
                </Link>
              </Flex>
            </Form>
          </Card>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            <RfFormField name="password" label='Şifre' kind='password' tabIndex={2} required focus />
            <RfFormField name="passwordAgain" label='Şifre (Tekrar)' kind='password' tabIndex={2} required />
            <RfFormError error={formProps.error} />
            <Button colorScheme={'brand'} isFullWidth tabIndex={4} submit isLoading={isSubmitting} >
              Gönder
            </Button>
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../' tabIndex={5}>
                Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationReset