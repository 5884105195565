import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import { Form, RfFormError, RfFormField, useReactorActionForm, yup } from 'reactor-form/components/RfForm'
import { useNavigate, useParams, Link } from 'reactor-vera/router'
import useReactor from 'reactor/hooks/useReactor'

// Reactor Ui
import { Flex, Box } from "reactor-ui"
import Button from 'reactor-ui/components/Button'
import Card from 'reactor-ui/components/Card'


const AuthenticationForgot = ({
  loginUrl,
  loginKey,
  forgotAction = 'RcApp.ForgotPassword',
  basePath
}) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()
  const reactor = useReactor()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  const [formProps, isSubmitting] = useReactorActionForm(
    forgotAction,
    {
      initialValues: {
        path: `${basePath}/reset`
      },
      // onSuccess: () => window.location.reload()
    }
  )

  if (formProps.phase === 'SUCCESS') {
    return (
      <Flex alignItems='center' justifyContent='center'>
      <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            {/* Belirttiğiniz e-posta adresine şifrenizi yenilemeniz için yapmanız gerekenleri içeren bir e-posta gönderdik. */}
            Şifreni sıfırlaman için gerekli talimatları gönderdik.
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to={`../`} tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={['90%', '50%', '50%', '50%', 400]} mt='20vh' pt='25px' mx='auto'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            <RfFormField name="email" label={loginKey || reactor?.rConfig?.loginKey || 'E-Posta'} tabIndex={1} schema={yup.string().min(4).required()} required focus />
            <RfFormError error={formProps.error} />
            <Button colorScheme={'brand'} isFullWidth tabIndex={4} submit isLoading={isSubmitting} >
              Gönder
            </Button>
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to={`../`} tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationForgot
