import React from 'react'

// Vendor
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// ReactorUi
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'

const ReactorVerai18n = ({
  lang,
  children
}) => {
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang', lang)
  const [ready, readySet] = React.useState(false)
  React.useEffect(() => {
    let langToUse = selectedLang
    if (langToUse == 'TURKISH') langToUse = 'tr'
    else if (langToUse == 'ENGLISH') langToUse = 'en'
    document.documentElement.setAttribute("lang", selectedLang)

    i18n
      .use(initReactI18next)
      .use(Backend)
      .init({
        lng: selectedLang,
        backend: {
          loadPath: '/__reactor/component/translations/get/{{lng}}/{{ns}}'
        },

        interpolation: {
          escapeValue: false
        }
      })
      readySet(true)
  }, [])
  if (!ready) return null
  return children || null
}

export default ReactorVerai18n