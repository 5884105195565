import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import GraphTable from 'reactor-vera/apps/data/components/GraphTable'
import { Routes, Route, useParams, Link } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import BareTabs from 'reactor-ui/components/BareTabs'
import Card from 'reactor-ui/components/Card'



const FmPaymentManagement = props => {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path='/' element={<DeviceManagementHome />} />
      <Route path='payment/:fmPaymentId/*' element={<DeviceManagementDetail />} />
    </Routes>
  )
}

const DeviceManagementHome = props => {
  const { t } = useTranslation()
  const params = useParams()
  return (
    <>
      <Stats />
      <GraphTable
        modelName='FmPayment'
        detailLink='payment'
      />
    </>
  )
}

const Stats = ({

}) => {
  const result = useReactorQuery({
    paymentStats: {
      // id: rnxAsSchedule.id
    }
  })

  const stats = result.graph?.paymentStats
  console.log('-', stats)

  if (!stats) return null

  return (
    <Card my={4}>
      <Card.Header>
        Ödeme İstatistikler
      </Card.Header>

      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4
      }}>
        <Stat label='Günlük' value={stats.daily}/>
        <Stat label='Haftalık' value={stats.weekly}/>
        <Stat label='Aylık' value={stats.monthly}/>
        <Stat label='Toplam' value={stats.total}/>

        <Stat label='Günlük ₺' value={stats.dailySum}/>
        <Stat label='Haftalık ₺' value={stats.weeklySum}/>
        <Stat label='Aylık ₺' value={stats.monthlySum}/>
        <Stat label='Toplam ₺' value={stats.totalSum}/>
      </Flex>
    </Card>
  )
}

const Stat = ({
  label, value
}) => {
  return (
    <Box sx={{
      flex: '1 1 20%',
      textAlign: 'center',
      my: 4,
      bg: 'brand.100',
      mx: 4,
      py: 4
    }}>
      <Box sx={{
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }}>
        {label}
      </Box>

      <Box sx={{
        color: 'brand.500',
        fontSize: '32'
      }}>
        {value || 0}
      </Box>
    </Box>
  )
}

const DeviceManagementDetail = ({

}) => {
  const params = useParams()
  const result = useModelDetailReactorQuery({
    'fmPayment': {
      filters: {
        id: params.fmPaymentId
      }
    },
  })

  useBreadcrumb(`fmPaymentDetail:${result.graph?.fmPayment.id}`, result.graph?.fmPayment.name)

  const fmPayment = result.graph?.fmPayment
  const meta = result.graph?.fmPaymentMeta

  if (!fmPayment) return null

  return (
    <>
      <EntityDetail
        icon={'book-open'}
        entity={fmPayment}
        schema={result.schema?.fmPayment}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />
      {/*
      <BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Program',
            codeName: 'program',
            element: <ActivityProgram fmPayment={fmPayment} />
          },

          {
            name: 'Türler',
            codeName: 'kinds',
            element: <ActivityKinds fmPayment={fmPayment} />
          }
        ]}
      />*/}

    </>
  )
}

export default FmPaymentManagement