import React from 'react'

// Vendor
import QRCode from "react-qr-code"

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import { useParams } from 'reactor-vera/router'


const FmQr = props => {
  const params = useParams()
  const value = {
    'id': params.qrKey
  }
  return (
    <Box sx={{
      mx: 'auto',
      width: '256px',
      textAlign: 'center',
      my: 10
    }}>
    <QRCode value={JSON.stringify(value)} />
    </Box>
  )
}

export default FmQr