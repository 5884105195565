import TenantManagement from './pages/TenantManagement'
import UserManagement from './pages/UserManagement'
import UserSettings from './pages/UserSettings'


export default {
  name: 'RcApp',
  components: {
    'TenantManagement': {
      component: TenantManagement
    },
    'UserManagement': {
      component: UserManagement
    },
    'UserSettings': {
      component: UserSettings
    },
  }
}