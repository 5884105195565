import React from 'react'

// ReactorUi
import ReactorUi from 'reactor-ui'

// Reactor
import ReactorVeraBase from './ReactorVeraBase'

import uiApp from 'reactor-vera/apps/ui'
// import modelApp from 'reactor-vera/apps/model'

import ReactorVera from 'reactor-vera/systems/reactor-vera'
import ReactorVerai18n from './components/ReactorVerai18n'

const defaultApps = [
  uiApp,
  // modelApp,
]

const defaultSystems = [
  ReactorVera
]

import useUiConfig from 'reactor-vera/hooks/useUiConfig'

// import RfFormProvider from 'reactor-form/components/RfFormProvider'

const ReactorVeraAppBase = ({
  theme,
  globalCss,
  uiConfig,
  apps = [],
  systems = [],
  storeCreator,
  config,
  children,
}) => {
  return (
    <ReactorVeraBase apps={[...defaultApps, ...apps]} systems={[...defaultSystems, ...systems]} storeCreator={storeCreator} config={config}>
      <ReactorVerai18n lang={'tr'} >
        <ReactorVeraUiConfig uiConfig={uiConfig} />
        <ReactorUi theme={theme} globalCss={globalCss}>
          {children}
        </ReactorUi>
      </ReactorVerai18n>
    </ReactorVeraBase>
  )
}

const ReactorVeraUiConfig = ({ uiConfig }) => {
  const [_, updateUiConfig] = useUiConfig()
  React.useEffect(() => {
    uiConfig && updateUiConfig(uiConfig)
  }, [])
  return null
}

export default ReactorVeraAppBase