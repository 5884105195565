import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Reactor
import createKey from 'reactor/util/key'
import reactorActions from 'reactor/systems/reactor/actions'
import useVisibility from './useVisibility'
import { getDenormalizedGraph } from 'reactor/core/graph'
import uniqueId from 'lodash/uniqueId'

const MIN_REFETCH_INTERVAL = 2 * 1000


const useReactorQuery = (queryObjOrString, options = {
  ready: true
}) => {
  const dispatch = useDispatch()
  let query = queryObjOrString
  const idRef = React.useRef()
  if (!idRef.current) idRef.current = uniqueId()
  const queryKey = idRef.current

  if (typeof queryObjOrString === 'string') {
    query = convertStringQuery(queryObjOrString)
  }

  // const queryKey = React.useMemo(() => createKey({s: query}), [query])
  // const queryKey = React.useMemo(() => createKey({s: query}), [])
  const fetchKey = React.useMemo(() => createKey({s: query}), [query])
  const queryStatus = useSelector(state => state.reactor.queries[queryKey])
  const { graph, status, schema, updateKey } = useSelector(state => {
    return getDenormalizedGraph(state, queryKey)
    // const current = getGraphData(state, fetchKey)
    // console.log('cur', current, lastFetchRef.current?.['lastFetchKey'], lastFetchRef.current?.['lastFetchKey'] && getDenormalizedGraph(state, lastFetchRef.current?.['lastFetchKey']))
    // if (current?.graph) return getDenormalizedGraph(state, fetchKey)
    // else if (lastFetchRef.current?.['lastFetchKey']) return getDenormalizedGraph(state, lastFetchRef.current?.['lastFetchKey'])
    // return {}
  })

  const visibility = useVisibility()

  const isFetchingRef = React.useRef()
  if (isFetchingRef.current === undefined) isFetchingRef.current = false

  const fetch = React.useCallback(() => {
    if (!options.ready) return
    if (isFetchingRef.current) return
    if (visibility !== 'visible') return
    isFetchingRef.current = true
    dispatch(reactorActions.reactorQuery({
      query,
      // variables,
      queryKey,
      callbacks: {
        success: () => {
          isFetchingRef.current = false
        },
        error: () => {
          isFetchingRef.current = false
        }
      }
    }))
  })

  React.useEffect(() => {
    if (isFetchingRef.current) return
    if (queryStatus?.status === 'FETCHING') return
    let fetchRequired = true

    // if (queryStatus?.status !== 'ERROR') {
    //   const lastFetch = queryStatus?.lastFetch
    //   if (lastFetch && new Date() - lastFetch < MIN_REFETCH_INTERVAL) fetchRequired = false
    // }
    const lastFetch = queryStatus?.lastFetch
    if (lastFetch && new Date() - lastFetch < MIN_REFETCH_INTERVAL) fetchRequired = false

    if (fetchRequired) fetch()
  }, [queryStatus])

  // React.useEffect(() => {
  //   if (!interval) return
  //   const _interval = setInterval(refresh, interval)
  //   return () => clearInterval(_interval)
  // }, [queryKey, interval])

  // fetch()

  React.useEffect(() => {
    fetch()
  }, [updateKey, fetchKey, options.ready])

  return {graph, schema, status, refresh: fetch}
}

const convertStringQuery = (queryString) => {
  const querySplit = queryString.split('.')
  const queryObj = {
    [querySplit[0]]: {
      fields: []
    }
  }
  let _obj = queryObj[querySplit[0]]
  for (let i = 1; i < querySplit.length -1; i++) {
    const key = querySplit[i]
    const keyObj = {
      fields: []
    }
    _obj.fields.push({
      [key]: keyObj
    })
    _obj = keyObj
  }
  _obj.fields.push(querySplit[querySplit.length - 1])

  return queryObj
}

const resolveStringQuery = (state, queryString) => {
  const keys = queryString.split('.')
  for (const key in keys) {
    const { objSchema, objValues } = resolveKey(state, key)
    if (!objSchema) return null
  }
}

const resolveKey = (state, key) => {
  const objSchema = state.schema[key]
  if (!objSchema) return {}
}

export default useReactorQuery