import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'
import AuthenticationBase from 'reactor-vera/apps/authentication/pages/AuthenticationBase'
import { Routes, Route, useParams, Link, useHref, useNavigate, Navigate } from 'reactor-vera/router'
import reactorActions from 'reactor/systems/reactor/actions'


const ChannelLogin = ({
  channel,
  loginKey,
  forgotAction,
  basePath
}) => {
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  const params = useParams()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: channel,
      },
      // rcTenantId: params.rcTenantId
    }))
    readySet(true)
  }, [])

  if (!ready) return null
  return (
    <Content channel={channel}>
      <AuthenticationBase basePath={basePath} loginUrl='/' loginKey={loginKey} forgotAction={forgotAction} />
      <Box textAlign='center'>
        <Link to='/'>
          Ana Sayfa
        </Link>
      </Box>
    </Content>
  )
}

const Content = ({
  children,
  channel
}) => {
  const status = useResourceQuery('rcApp.state.status')
  const user = status?.rcUser
  const navigate = useNavigate()

  React.useEffect(() => {
    if (user) {
      // navigate(`/auth/login`)
      if (channel === 'ReactorPublic') navigate(`/member/${user.rcTenantId}`)
      else navigate(`/home`)
    }
  }, [user])
  if (!status) return null
  return children
}

export default ChannelLogin