import React from 'react'

// Vendor
import { useFormState, useForm } from 'react-final-form'
import { setIn, getIn } from 'final-form'
import { ReactorFormContext } from '../components/RfForm'


const useShowIf = (showIf, fieldNames, parentPath) => {
  const form = useForm()
  const formState = useFormState()
  const prevShofIfRef = React.useRef()
  if (!prevShofIfRef.current) prevShofIfRef.current = false

  const ctx = React.useContext(ReactorFormContext)

  let result = true

  if (showIf) {
    showIf.forEach(fieldShowIf => {
      let fullFieldName
      if (fieldShowIf.field) {
        fullFieldName = parentPath ? `${parentPath}.${fieldShowIf.field}` : fieldShowIf.field
      } else if (fieldShowIf.fieldPath) {
        fullFieldName = fieldShowIf.fieldPath
      }
      let value = getIn(formState.values, fullFieldName)
      if (fieldShowIf.entityField) {
        if (value === undefined || value === null) {
          result = false
          return
        }
        const ctxValue = ctx.entityMap[fieldShowIf.field][value]
        if (ctxValue === undefined || ctxValue === null) {
          result = false
          return
        }

        value = getIn(ctxValue, fieldShowIf.entityField)
      }

      if (fieldShowIf.comparison === 'EQUAL' || fieldShowIf.comparison === 'EQUALS') {
        if (value !== fieldShowIf.value) result = false
      }

      else if (fieldShowIf.comparison === 'NOT_EQUAL' || fieldShowIf.comparison === 'NOT_EQUALS') {
        if ((value === undefined) || (value === fieldShowIf.value)) result = false
      }

      else if (fieldShowIf.comparison === 'EXIST' || fieldShowIf.comparison === 'EXISTS') {
        if ((value === undefined) || (value === null)) result = false
      }

      if (fieldShowIf.comparison === 'IN') {
        if (!fieldShowIf.value.includes(value)) result = false
      }

      if (fieldShowIf.comparison === 'INCLUDES') {
        if (!value?.includes(fieldShowIf.value)) result = false
      }
      // if (!result) console.log(field.name, result, value, fullFieldName)
    })
  }

  React.useEffect(() => {
    const prevShowIf = prevShofIfRef.current
    prevShofIfRef.current = result
    if (!showIf) return
    if (result) return
    if (!prevShowIf) return
    if (!fieldNames) return

    // const fullFieldName = `${parentPath}.${field.name}`
    fieldNames.forEach(name => {
      const splitName = name.split('.')
      const parentName = splitName.slice(0, splitName.length - 1).join('.')
      const fieldName = splitName[splitName.length - 1]
      const parentValue = getIn(formState.values, parentName)
      const newParentValue = setIn(parentValue || {}, fieldName, undefined)
      if (parentValue === newParentValue) return
      if (!parentValue?.[fieldName]) return
      console.log('changing', name, fieldName, parentName, parentValue, newParentValue, parentValue === newParentValue, result)
      form.change(parentName, newParentValue)
    })
  }, [result])

  return result
}

export default useShowIf