import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import * as changeCase from 'change-case'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@emotion/react'
import axios from 'axios'
import { useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { useFormState } from 'react-final-form'
import { setIn, getIn } from 'final-form'
import isArray from 'lodash/isArray'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'

// Reactor
import useFetchKey from 'reactor/hooks/useFetchKey'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'
import { ReactorFormContext } from './RfForm'

import useParseValuesFromServer from '../hooks/useParseValuesFromServer'

// FIXME: dont depend on reactor vera?
import ReactorServerAction from 'reactor-vera/apps/data/components/ReactorServerAction'

const useHasInitialValue = input => {
  const hasInitialValue = React.useRef()
  if (hasInitialValue.current === undefined) {
    if (input.value != '') hasInitialValue.current = true
    else hasInitialValue.current = null
  }
  return hasInitialValue
}

const useFetch = ({
  field,
  ctx,
  kind,
}) => {
  const rcTenantId = useSelector(state => state.reactor.activeTenantId)
  let platform
  if (field.platform) {
    platform = field.platform
  } else {
    platform = useSelector((state) => state.reactor.config.channel)
  }
  let headers = {}
  if (platform) {
    headers['X-REACTOR-CHANNEL'] = platform
  }
  let lookupBaseUrl

  if (field.url) lookupBaseUrl = field.url
  else lookupBaseUrl = field.remote ? ctx?.remoteGraphBaseUrl || '/__reactor/api/reactor_query' : ctx?.graphBaseUrl || '/__reactor/api/reactor_query'

  const variables = null
  let fieldName = field.name
  if (field.parentPath) {
    fieldName = field.name.replace(`${field.parentPath}.`, '')
  }
  let queryName = field.queryName
  if (field.options?.modelName) {
    queryName = `${field.options?.modelName}Lookup`
  } else {
    const fieldNameSplit = fieldName.split('.')
    const modelNameFromField = fieldNameSplit[fieldNameSplit.length - 1]
    queryName = `${modelNameFromField}Lookup`
  }

  const fetch = async ({ value, params, fetchKey, onSuccess }) => {
    console.log('--', params)
    let nameField = 'name'
    if (field.options?.nameField) {
      nameField = field.options?.nameField
    }
    let fields = [nameField]

    const filters = params || {}
    if (value) {
      if (kind === 'id') {
        filters.id = value
      } else {
        filters[field.options?.filterField || nameField] = value
      }
    }

    let query
    if (field.createQuery) {
      query = field.createQuery({ filters })
    } else {
      if (params?.fields) {
        fields = [...params.fields]
        delete params.fields
      } else if (field.options?.fields) {
        fields = field.options?.fields
      }
      query = {
        [queryName]: {
          // 'order_by': 'permission_code_name|asc',
          systemQuery: field.options?.systemQuery,
          filters,
          itemFields: fields,
          // fields: [
          //   'items',
          //   'count'
          // ]
        }
      }
    }

    const res = await axios.get(`${lookupBaseUrl}`, { params: { '@rcTenantId': rcTenantId, query, variables, fetchKey }, headers }).catch(err => err)
    if (!res || res.err) {
      // console.log('err')
    } else {
      // console.log('--', res)
      let items
      if (field.resolver) {
        items = field.resolver(res?.data)
      } else {
        items = res?.data?.data?.[queryName]?.items?.map(x => ({
          id: x.id,
          item: x
        }))
      }
      onSuccess(items)
    }
  }

  return fetch
}

const useSetExistingValue = ({
  field,
  input,
  ctx,
  onSuccess,
  value,
  valueSet,
  isReadySet
}) => {
  const fetch = useFetch({
    field,
    ctx,
    kind: 'id'
  })

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  let params = {}
  if (field.options?.params) {
    Object.keys(field.options?.params).forEach(key => {
      const param = field.options?.params[key]
      if (param?._kind === 'dynamic') {
        let paramValue
        if (param.valueSource === 'form') {
          paramValue = getIn(formState.values, param.valueKey)
        } else if (param.valueSource === 'group') {
          paramValue = getIn(formState.values, `${field.parentPath}.${param.valueKey}`)
        }

        if (param.filter) {
          if (!params['filters']) params['filters'] = {}
          params['filters'][key] = paramValue
        } else {
          params[key] = paramValue
        }
      } else if (param?.includes?.('@@r.form.')) {
        const keys = param.replace('@@r.form.', '')
        const resolvedValue = getIn(formState.values, keys)
        params[key] = resolvedValue
      } else {
        params[key] = param
      }
    })
  }

  if (ctx?.sourceCtx) {
    params = {
      ...params,
      ...ctx.sourceCtx
    }
  }

  const key = useFetchKey(Object.values(params))

  React.useEffect(() => {
    // console.log('k', input.value, value)
    if (input.value == '' || input.value.length === 0) return
    if (input.value === value?.value) return
    if (value === null) return

    if (field.isMulti) {
      if (input.value.length > 0) return
    }
    // console.log('u', field, input.value, value)
    const kind = field.modelName

    if (field.isMulti) {
      const values = []
      input.value?.forEach(val => {
        const existing = ctx.entityMap?.[kind]?.[val]
        if (existing) {
          values.push({
            value: existing.id,
            label: existing.name,
            item: existing
          })
        }
      })
      valueSet(values)
      isReadySet(true)
      return
    } else {
      const existing = ctx.entityMap?.[kind]?.[input.value]
      if (existing) {
        valueSet({
          value: existing.id,
          label: existing.name,
          item: existing
        })
        isReadySet(true)
        return
      }
    }

    fetch({
      value: input.value,
      fetchKey: key,
      params,
      onSuccess,
    })
  }, [input.value, value])
}

const RfEntityField = ({
  name,
  field,
  sx,
  ...rest
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })

  const ctx = React.useContext(ReactorFormContext)

  // const hasInitialValue = useHasInitialValue(input)

  const [isReady, isReadySet] = React.useState(input.value == '')
  const [value, valueSet] = React.useState()
  const [open, openSet] = React.useState()

  useSetExistingValue({
    field,
    input,
    ctx,
    onSuccess: (items) => {
      if (!items || items.length === 0) {
        isReadySet(true)
      } else {
        // console.log('res', res)
        valueSet({
          value: items[0].id,
          label: items[0].item.name,
          item: items[0].item
        })
        isReadySet(true)
      }
    },
    value,
    valueSet,
    isReadySet
  })

  const onChange = (newVal) => {
    valueSet(newVal)
    const kind = field.options?.modelName || field.name
    if (newVal) {
      if (ctx?.entityMap && !ctx.entityMap[kind]) ctx.entityMap[kind] = {}

      if (isArray(newVal)) {
        if (ctx?.entityMap) newVal.forEach(v => ctx.entityMap[kind][v.value] = v.item)
        input.onChange(newVal.map(v => v.value))
        field?.onChange?.(newVal.map(v => v.value))
      } else {
        if (ctx?.entityMap) ctx.entityMap[kind][newVal.value] = newVal.item
        input.onChange(newVal.value)
        field?.onChange?.(newVal.value, newVal)
      }
      openSet(false)
    } else {
      input.onChange(newVal)
      field?.onChange?.(newVal)
      openSet(false)
    }
  }

  // React.useEffect(() => {
  //   const kind = field.modelName
  //   if (value) {
  //     if (ctx?.entityMap && !ctx.entityMap[kind]) ctx.entityMap[kind] = {}

  //     if (isArray(value)) {
  //       if (ctx?.entityMap) value.forEach(v => ctx.entityMap[kind][v.value] = v.item)
  //       input.onChange(value.map(v => v.value))
  //       field?.onChange?.(value.map(v => v.value))
  //     } else {
  //       if (ctx?.entityMap) ctx.entityMap[kind][value.value] = value.item
  //       input.onChange(value.value)
  //       field?.onChange?.(value.value, value)
  //     }
  //     openSet(false)
  //   } else {
  //     input.onChange(value)
  //     field?.onChange?.(value)
  //     openSet(false)
  //   }
  // }, [value])

  if (!isReady) return null

  return (
    <>
      <Flex sx={{
        alignItems: 'center',
        width: '100%',
        ...sx,
        ...field.sx
      }}>
        <Box sx={{
          width: '100%',
          flex: '1 1 0'
        }}>
          <Lookup field={field} value={value} onChange={onChange} ctx={ctx} />
        </Box>

        {/* const Create = ctx?.createComponents?.[field.modelName] */}
        {/* {Create && <Box sx={{
        flex: '0 0 40px',
        textAlign: 'center'
      }}>
        <Create field={field} onSuccess={rxBlock => {
          onChange({
            value: rxBlock.id,
            label: rxBlock.name,
            item: rxBlock
          })
        }} />
      </Box>} */}

        {field.options?.createActions?.map((action, dx) => {
          return (
            <ReactorServerAction
              anchor={<Button sx={{ml: 2}} colorScheme='brand' iconName='plus'/>}
              identifier={action.identifier}
              onSuccess={(data) => {
                input.onChange(data['created'][0]['id'])
              }}
              />
          )
        })}
      </Flex>
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} />
    </>
  )
}

// const CustomOption = (props) => {
//   const { innerProps, isDisabled, getValue, children, getStyles, selectProps, data } = props
//   const renderer = selectProps.field.optionValueRenderer
//   return <components.Option {...props}>{renderer ? renderer({data: data}) : children}</components.Option>
// }

const Lookup = ({
  field,
  value,
  onChange,
  ctx
}) => {
  const { t } = useTranslation()
  const isFirstFetchRef = React.useRef()
  if (isFirstFetchRef.current === undefined) isFirstFetchRef.current = true
  const theme = useTheme()
  const formState = useFormState({
    subscription: {
      values: true
    }
  })
  const fetch = useFetch({
    field,
    ctx,
    kind: 'value'
  })

  let modelName
  if (field.options?.modelName) {
    modelName = `${field.options?.modelName}`
  } else {
    const fieldNameSplit = field.name.split('.')
    const modelNameFromField = fieldNameSplit[fieldNameSplit.length - 1]
    modelName = `${modelNameFromField}`
  }

  let params = {}
  if (field.options?.params) {
    params = useParseValuesFromServer(field.options?.params, ctx)
    // Object.keys(field.options?.params).forEach(key => {
    //   const param = field.options?.params[key]
    //   console.log('param', param, field.options?.params, key)
    //   if (param?._kind === 'dynamic') {
    //     let paramValue
    //     if (param.valueSource === 'form') {
    //       paramValue = getIn(formState.values, param.valueKey)
    //     } else if (param.valueSource === 'group') {
    //       paramValue = getIn(formState.values, `${field.parentPath}.${param.valueKey}`)
    //     }

    //     if (param.filter) {
    //       if (!params['filters']) params['filters'] = {}
    //       params['filters'][key] = paramValue
    //     } else {
    //       params[key] = paramValue
    //     }
    //   } else if (param?.includes?.('@@r.form.')) {
    //     const keys = param.replace('@@r.form.', '')
    //     const resolvedValue = getIn(formState.values, keys)
    //     params[key] = resolvedValue
    //   } else if (param?.includes?.('@@r.ctx.')) {
    //     const keys = param.replace('@@r.ctx.', '')
    //     console.log('----', ctx, key)
    //     const resolvedValue = getIn(ctx, keys)
    //     params[key] = resolvedValue
    //   } else {
    //     params[key] = param
    //   }
    // })
  }

  if (ctx?.sourceCtx) {
    params = {
      ...params,
      ...ctx.sourceCtx
    }
  }

  const key = useFetchKey(Object.values(params))

  // const customComponents = {}
  // if (field.optionValueRenderer) {
  //   customComponents['Option'] = CustomOption
  //   customComponents['ValueContainer'] = CustomValue
  // }

  return (

    <AsyncSelect
      key={key}
      field={field}
      // onChange={(props) => {
      //   field?.isMulti ? input.onChange(props?.map(x => x.value)) : input.onChange(props?.value)
      // }}
      // value={options.find(x => x.value === input.value)}
      // onChange={onChange}
      onChange={(props) => {
        onChange(props)
        // field.onChange?.(props)
      }}
      value={value}
      isMulti={field.isMulti}
      closeMenuOnSelect={!field.isMulti}
      // defaultOptions={field.defaultOptions}
      defaultOptions
      loadingMessage={field.loadingMessage}
      noOptionsMessage={field.noOptionsMessage}
      defaultValue={field.defaultValue}
      tabSelectsValue={false}
      isClearable={true}
      loadOptions={(input, cb) => {
        fetch({
          value: input,
          params,
          fetchKey: key,
          onSuccess: items => {
            let finalItems = field.defaultOptions ? [...field.defaultOptions] : []
            finalItems = [
              ...finalItems,
              ...items?.map?.(x => {
                if (!x || !x?.item) return
                return ({
                  value: x.id,
                  label: field.options?.nameField ? x?.item?.[field.options?.nameField] : x?.item?.name,
                  item: x?.item
                })
              })
            ]
            cb(finalItems)
            if (isFirstFetchRef.current) {
              isFirstFetchRef.current = false
              if (field.autoSelectIfOne && finalItems.length === 1) {
                onChange(finalItems[0])
              }
            }
          }
        })
      }}
      // options={options}
      // components={customComponents}
      formatOptionLabel={field.formatOptionLabel}
      getOptionLabel={field.getOptionLabel}
      cacheOptions
      // cacheOptions={false}
      placeholder={field.placeholder || changeCase.title(t(`label.${changeCase.snake(modelName)}`))}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.primary,
          paddingTop: 4,
          paddingBottom: 4,
          ...field?.styles?.control,
        }),
        container: (provided, state) => ({
          ...provided,
          width: '100%',
        }),
        option: (provided, state) => ({
          ...provided,
          color: '#000'
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: 9
        })
      }} />
  )
}

export default RfEntityField