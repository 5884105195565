import React from 'react'

// Vendor
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import dayjs from 'dayjs'


// import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.locale('tr')
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.extend(customParseFormat)
import 'react-day-picker/lib/style.css'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import TextInput from 'reactor-ui/components/TextInput'
import Portal from 'reactor-ui/components/Portal'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useDisclosure
} from 'reactor-ui/components/Popover'

function parseDate(str, format, locale) {
  const parsed = dayjs(str, format, locale)
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dayjs(date).format(format)
}


const MONTHS = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
]
const WEEKDAYS_LONG = [
  'Pazar',
  'Pazartesi',
  'Salı',
  'Çarşamba',
  'Perşembe',
  'Cuma',
  'Cumartesi',
]
const WEEKDAYS_SHORT = ['Pz', 'Pzt', 'Sl', 'Çr', 'Pr', 'Cu', 'Ct']


const DayPickerOverlay = ({
  classNames, selectedDay, children, onBlur, onFocus, ...props
}) => {
  return (
    <Portal sx={{
      zIndex: 9999
    }} appendToParentPortal={true}>
      <Box sx={{
        zIndex: 9999
      }}>
      <PopoverContent onBlur={onBlur} onFocus={onFocus}>
        <PopoverArrow />
        {/* <PopoverHeader>Header</PopoverHeader>
          <PopoverCloseButton /> */}
        <PopoverBody sx={{
          zIndex: 9999
        }}>
          {children}
        </PopoverBody>
      </PopoverContent>
      </Box>
    </Portal>
  )
}

const Input = React.forwardRef((props, ref) => {
  return (
    <TextInput sx={{
      width: '100%',
      ...props.sx
    }} ref={ref} requiredLabel={false} {...props}/>
  )
})

const DateInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder = 'Tarih',
  format = 'DD.MM.YYYY',
  label
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Popover
      isLazy
      returnFocusOnClose={false}
      autoFocus={false}
      closeOnBlur={false}
      isOpen={isOpen}
      matchWidth
    >
      <PopoverTrigger>
        <Box sx={{
          color: '#000',
          '.DayPickerInput-Overlay': {
            zIndex: 9,
          },
        }}>
          <DayPickerInput
            // keepFocus={false}
            inputProps={{
              label,
              onFocus,
              onBlur
            }}
            onDayPickerHide={onClose}
            onDayPickerShow={onOpen}
            value={value}
            format={format}
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={onChange}
            placeholder={placeholder}
            // component={Input}
            component={Input}
            overlayComponent={DayPickerOverlay}
            style={{
              display: 'block',
              zIndex: 9
            }}
            dayPickerProps={{
              locale: 'tr',
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
            }} />
        </Box>
      </PopoverTrigger>
    </Popover>
  )
}

export default DateInput