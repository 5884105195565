import RnxBroadcastManagement from './pages/RnxBroadcastManagement'


export default {
  name: 'RnxBroadcast',
  components: {
    'RnxBroadcastManagement': {
      component: RnxBroadcastManagement
    },
  }
}