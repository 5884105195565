import React from 'react'

import useResourceFetch from 'reactor/hooks/useResourceFetch'
import useParseValuesFromServer from './useParseValuesFromServer'
import { ReactorFormContext } from '../components/RfForm'


const useLoader = (loader) => {
  const ctx = React.useContext(ReactorFormContext)
  let result
  if (loader.kind === 'resource') result = useResourceLoader(loader.options, ctx)
  else throw Error(`INVALID_LOADER: ${loader.kind}`)
  return result
}

export const useResourceLoader = (loader, ctx) => {
  const { resourceIdentifier, values: valuesFromOptions } = loader
  const values = useParseValuesFromServer(valuesFromOptions, ctx)
  const resourceFetch = useResourceFetch(resourceIdentifier)

  const fetch = async (value) => {
    const result = await resourceFetch({ ...values, name: value })
    return result.data
  }

  return { fetch, values }
}

export default useLoader