import React from 'react'

// Reactor
import useReactor from 'reactor/hooks/useReactor'


const PlexUiDomainRouter = ({
  zone,
  domain
}) => {
  const reactor = useReactor()
  const DomainHandler = reactor.directory.reactorVera?.components?.[domain.componentKey]

  if (!DomainHandler) {
    console.warn('Domain handler not found for domain', domain, reactor.directory.reactorVera?.components)
    return null
  }

  return <DomainHandler zone={zone} domain={domain}/>
}

export default PlexUiDomainRouter