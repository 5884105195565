import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// ReactorUi
import { Box, Flex } from 'reactor-ui'

import UserMembership from 'elements/rnx_membership/rnxMembership/pages/UserMembership'
import { Routes, Route, useParams, Link, useHref, useNavigate, Navigate } from 'reactor-vera/router'

// Reactor
import reactorActions from 'reactor/systems/reactor/actions'


const MemberHome = props => {
  const [ready, readySet] = React.useState()
  const dispatch = useDispatch()
  const params = useParams()
  React.useEffect(() => {
    dispatch(reactorActions.setup({
      config: {
        channel: 'ReactorPublic',
      },
      rcTenantId: params.rcTenantId
    }))
    readySet(true)
  }, [])

  if (!ready) return null
  return (
    <Box sx={{
      width: ['90%', null, null, '70%'],
      mx: 'auto',
      mt: [4, null, null, 20]
    }}>
      <UserMembership/>
    </Box>
  )
}

export default MemberHome