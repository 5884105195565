import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Volcano
import { Box, Flex } from 'reactor-ui'
import { Checkbox } from 'reactor-ui/components/Checkbox'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfContractField = ({
  name,
  field,
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
    type: 'checkbox'
  })
  console.log(field)
  return (
    <Box sx={{
      my: 8
    }}>
      <Box sx={{
        fontSize: 'sm'
      }}>
        {field.options?.header}
      </Box>
      <Box as='textarea' sx={{
        width: '95%',
        height: 100,
        mt: 2,
        // ml: 2
      }} value={field.options?.text} disabled />
      <Checkbox {...input} tabIndex={field.tabIndex}>{field.label}</Checkbox>
      <RfFieldAlert meta={meta}/>
      <RfFieldDescription field={field} position='default'/>
    </Box>
  )
}

export default RfContractField