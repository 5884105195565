import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTable } from 'react-table'

// Reactor
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'
import EntityDetail from 'reactor-vera/apps/model/components/EntityDetail'
import useModelDetailReactorQuery from 'reactor/hooks/useModelDetailReactorQuery'
import ActionGroup, { ActionGroupMenu } from 'reactor-vera/apps/data/components/ActionGroup'
import RfActionForm from 'reactor-form/components/RfActionForm'
import Enum from 'reactor-vera/apps/model/components/Enum'
import Bool from 'reactor-vera/apps/model/components/Bool'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import Image, { LightboxImage } from 'reactor-ui/components/Image'


const RnxMembershipManagement = props => {
  const { t } = useTranslation()
  return (
    <RV.Routes>
      <RV.Route path='/' element={<MembershipManagementHome />} />
      <RV.Route path='program/:rnxMembershipProgramId/*' element={<MembershipManagementDetail />} />
    </RV.Routes>
  )
}

const MembershipManagementHome = props => {
  const { t } = useTranslation()
  const params = RV.useParams()
  return (
    <>
      <RV.GraphTable
        modelName='RnxMembershipProgram'
        detailLink='program'
        query={{
          filters: {
            parentId: null
          }
        }}
      />
    </>
  )
}

const MembershipManagementDetail = ({

}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rnxMembershipProgram': {
      filters: {
        id: params.rnxMembershipProgramId
      }
    },
  })

  useBreadcrumb(`rnxMembershipProgramDetail:${result.graph?.rnxMembershipProgram.id}`, result.graph?.rnxMembershipProgram.name)

  const rnxMembershipProgram = result.graph?.rnxMembershipProgram
  const meta = result.graph?.rnxMembershipProgramMeta
  console.log('rnxMembershipProgram', rnxMembershipProgram)
  if (!rnxMembershipProgram) return null

  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <>
          <EntityDetail
            icon={'book-open'}
            entity={rnxMembershipProgram}
            schema={result.schema?.rnxMembershipProgram}
            fields={meta.primaryFields}
          // refresh={result.refresh}
          // status={result.status}
          />

          <RV.BareTabs
            // isFitted
            isLazy
            tabs={[
              {
                name: 'Üyeler',
                codeName: 'members',
                element: <Members rnxMembershipProgram={rnxMembershipProgram} />
              },

              {
                name: 'Başvurular',
                codeName: 'applications',
                element: <Applications rnxMembershipProgram={rnxMembershipProgram} />
              },

              {
                name: 'Üyelik Türleri',
                codeName: 'subprograms',
                element: <SubPrograms rnxMembershipProgram={rnxMembershipProgram} />
              },

              {
                name: 'Ayarlar',
                codeName: 'settings',
                element: <ProgramSettings rnxMembershipProgram={rnxMembershipProgram} />
              },
            ]}
          />

        </>
      )} />
      <RV.Route path='kind/:rnxMembershipKindId/*' element={<MembershipKindDetail parent={rnxMembershipProgram} />} />
      <RV.Route path='application/:rcFormDataId/*' element={<ApplicationDetail parent={rnxMembershipProgram} />} />
      <RV.Route path='member/:rnxMembershipId/*' element={<MembershipDetail program={rnxMembershipProgram} />} />
    </RV.Routes>
  )
}

const ApplicationDetail = ({

}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rcFormData': {
      filters: {
        id: params.rcFormDataId,
      },
      fields: [
        'name', 'note', 'status', 'values', { rcForm: { fields: ['name', 'formFields'] } }
      ]
    },
  })

  useBreadcrumb(`rcFormData:${result.graph?.rcFormData.id}`, result.graph?.rcFormData.name)

  const rcFormData = result.graph?.rcFormData
  const meta = result.graph?.rcFormDataMeta
  console.log('rcFormData', rcFormData)
  if (!rcFormData) return null
  return (
    <>
      <EntityDetail
        icon={'book-open'}
        parentName={parent.name}
        entity={rcFormData}
        schema={result.schema?.rcFormData}
        fields={['name', 'status', 'note']}
        elements={{
          primaryActions: [
            <RfActionForm name='ücretsiz olarak onayla' actionKey='FmBase.ApproveMembershipFromFormWithoutPayment' fields={[

            ]} buttonText={'onayla'} initialValues={{
              id: rcFormData.id
            }} onSuccess={() => result.refresh()} sx={{
              mx: 2
            }} key='approveAdmin' fields={[
              // {
              //   name: 'credit',
              //   label: 'Kredi',
              //   kind: 'number',
              //   required: true
              // }
            ]} />,

            <RfActionForm name='onayla' actionKey='FmBase.ApproveMembershipFromForm' fields={[

            ]} buttonText={'onayla'} initialValues={{
              id: rcFormData.id
            }} onSuccess={() => result.refresh()} sx={{
              mx: 2
            }} key='approve' />,

            <RfActionForm name='reddet' actionKey='FmBase.RejectMembershipFromForm' fields={[
              {
                name: 'reason',
                kind: 'text',
                label: 'Nedeni?',
                required: true
              }
            ]} buttonText={'reddet'} initialValues={{
              id: rcFormData.id
            }} onSuccess={() => result.refresh()} sx={{
              mx: 2
            }} key='reject' />
          ]
        }}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.Card sx={{
        my: 4
      }}>
        <Box sx={{
          fontWeight: 'bold'
        }}>
          Form Bilgileri
        </Box>
        {rcFormData.rcForm.formFields.map((f, dx) => {
          if (f.kind === 'password') return null
          const value = rcFormData.values[f.name]
          let valueContent

          if (!value) {
            valueContent = ''
          }
          else if (f.kind === 'file') {
            valueContent = (
              <>
                <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                <LightboxImage src={value.url} sx={{
                  width: '200px'
                }} />
              </>
            )
          } else if (f.kind === 'entity') {
            valueContent = value.name
          } else if (f.kind === 'radio') {
            valueContent = <Enum value={value} />
          } else if (f.kind === 'bool') {
            valueContent = <Bool value={value} />
          } else {
            valueContent = value
          }
          return (
            <Box key={dx} sx={{
              my: 2
            }}>
              <Box fontWeight='bold'>
                {f.label}
              </Box>

              <Box>
                {valueContent}
              </Box>
            </Box>
          )
        })}
      </RV.Card>
    </>
  )
}

const Applications = ({
  rnxMembershipProgram
}) => {
  return (
    <>
      <RV.BareTabs
        sx={{
          mt: 4
        }}
        isFitted
        isLazy
        tabs={[
          {
            name: 'Bekleyen',
            codeName: 'waiting',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    kind: 'FACILITY_REGISTRATION_FORM',
                    status: 'WAITING'
                  }
                }}
                modelName='RcFormData'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },

          {
            name: 'Onaylanan',
            codeName: 'approved',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    kind: 'FACILITY_REGISTRATION_FORM',
                    status: 'DONE'
                  }
                }}
                modelName='RcFormData'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },

          {
            name: 'Reddedilen',
            codeName: 'rejected',
            element: (
              <RV.GraphTable
                sx={{
                  mt: 4
                }}
                query={{
                  filters: {
                    kind: 'FACILITY_REGISTRATION_FORM',
                    status: 'INVALID'
                  }
                }}
                modelName='RcFormData'
                detailLink='application'
                headerText='Başvurular'
                showEntityActions={false}
              // actionCtx={{
              //   model: {
              //     rnx_membership_program_id: rnxMembershipProgram.id
              //   }
              // }}
              // query={{
              //   filters: {
              //     parentId: null
              //   }
              // }}
              />
            )
          },
        ]}
      />
    </>
  )
}

const Members = ({
  rnxMembershipProgram
}) => {
  return (
    <>
      <RV.GraphTable
        sx={{
          mt: 4
        }}
        filters={[
          {
            label: 'İsim',
            kind: 'String',
            target: 'rnx_contact.name',
          },

          {
            label: 'T.C Kimlik No',
            kind: 'String',
            target: 'rnx_contact.gov_id'
          }
        ]}
        modelName='RnxMembership'
        detailLink='member'
        headerText='Üyeler'
        exporters={[
          {
            identifier: 'RnxMembership.RnxMembershipProgramMemberExporter',
            params: {
              rnxMembershipProgram: rnxMembershipProgram.id
            }
          }
        ]}
      />
    </>
  )
}

const MembershipDetail = ({

}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rnxMembership': {
      filters: {
        id: params.rnxMembershipId,
      },
      fields: [
        'name', { rnxMembershipProgram: { fields: ['name', 'allCustomMembershipParameters'] } },
        'datetimeStart', 'status', 'info',
        { rnxContact: { fields: ['name', 'govId'] } },
      ]
      // fields: [
      //   'name', 'status', 'values', { rcForm: { fields: ['name', 'formFields'] } }
      // ]
    },
  })

  useBreadcrumb(`rnxMembership:${result.graph?.rnxMembership.id}`, result.graph?.rnxMembership.name)

  const rnxMembership = result.graph?.rnxMembership
  const meta = result.graph?.rnxMembershipMeta
  console.log('rnxMembership', rnxMembership)
  if (!rnxMembership) return null
  return (
    <>
      <EntityDetail
        icon={'book-open'}
        parentName={parent.name}
        entity={rnxMembership}
        schema={result.schema?.rnxMembership}
        fields={[
          'name', 'rnxMembershipProgram', 'datetimeStart', 'status', 'rnxContact.govId'
        ]}
      />

      <RV.Layout.Cols>

        <Box>

          <RV.GraphTable
            modelName='RnxPacAccessProvider'
            headerText='Kartlar'
            elements={{
              primaryActions: [
                <RfActionForm
                  name='Kart Ekle'
                  actionKey='FmBase.AddCardToMember'
                  fields={[
                    {
                      name: 'serial',
                      label: 'Seri No',
                      kind: 'text',
                      required: true
                    },
                  ]}
                  buttonText={'Kart Ekle'}
                  initialValues={{
                    id: rnxMembership.id
                  }}
                  onSuccess={() => result.refresh()}
                  sx={{
                    mx: 2
                  }}
                  key='add' />,
              ]
            }}
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />

          <RV.GraphTable
            modelName='RnxResourceBalance'
            headerText='Krediler'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                owner_entity_key: rnxMembership.id
              }
            }}
          />

          <RV.Card>
            <RV.Card.Header>
              BİLGİLER
            </RV.Card.Header>

            {rnxMembership.rnxMembershipProgram?.allCustomMembershipParameters?.map((p, dx) => {
              const value = rnxMembership.info[p.code_name]
              let valueContent
              console.log(p)
              if (!value) {
                valueContent = ''
              }
              else if (p.kind === 'FILE') {
                valueContent = (
                  <>
                    <Box as='a' color='brand.500' href={value.url} target='_blank'>İndir</Box>
                    <LightboxImage src={value.url} sx={{
                      width: '200px'
                    }} />
                  </>
                )
              } else if (p.kind === 'ENTITY') {
                valueContent = value.name
              } else if (p.kind === 'RADIO') {
                valueContent = <Enum value={value} />
              } else if (p.kind === 'BOOL') {
                valueContent = <Bool value={value} />
              } else {
                valueContent = value
              }
              return (
                <Flex alignItems='center' my={2} key={dx}>
                  <Box>
                    {p.name}
                  </Box>

                  <Box>
                    {valueContent}
                  </Box>
                </Flex>
              )
            })}
          </RV.Card>

        </Box>

        <Box>

          <RV.GraphTable
            modelName='FmPayment'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />
          <RV.GraphTable
            modelName='RnxPacAccessRecord'
            sx={{
              my: 4
            }}
            // detailLink='program'
            query={{
              filters: {
                rnx_contact_id: rnxMembership.rnxContact.id
              }
            }}
          />



        </Box>

      </RV.Layout.Cols>

      {/* <RV.Card sx={{
        my: 4
      }}>
        <Box sx={{
          fontWeight: 'bold'
        }}>
          Form Bilgileri
        </Box>
        {rcFormData.rcForm.formFields.map((f, dx) => {
          if (f.kind === 'password') return null
          const value = rcFormData.values[f.name]
          let valueContent

          if (f.kind === 'file') {
            valueContent = <Image src={value.url} sx={{
              width: '200px'
            }} />
          } else if (f.kind === 'entity') {
            valueContent = value.name
          } else {
            valueContent = value
          }
          return (
            <Box key={dx} sx={{
              my: 2
            }}>
              <Box fontWeight='bold'>
                {f.label}
              </Box>

              <Box>
                {valueContent}
              </Box>
            </Box>
          )
        })}
      </RV.Card> */}
    </>
  )
}

const MembershipKindDetail = ({
  parent
}) => {
  const params = RV.useParams()
  const result = useModelDetailReactorQuery({
    'rnxMembershipProgram': {
      filters: {
        id: params.rnxMembershipKindId
      }
    },
  })

  useBreadcrumb(`rnxMembershipProgramDetail:${result.graph?.rnxMembershipProgram.id}`, result.graph?.rnxMembershipProgram.name)

  const rnxMembershipProgram = result.graph?.rnxMembershipProgram
  const meta = result.graph?.rnxMembershipProgramMeta
  console.log('rnxMembershipProgram', rnxMembershipProgram)
  if (!rnxMembershipProgram) return null
  return (
    <>
      <EntityDetail
        icon={'book-open'}
        parentName={parent.name}
        entity={rnxMembershipProgram}
        schema={result.schema?.rnxMembershipProgram}
        fields={meta.primaryFields}
      // refresh={result.refresh}
      // status={result.status}
      />

      <RV.BareTabs
        isFitted
        isLazy
        tabs={[
          {
            name: 'Paketler',
            codeName: 'packages',
            element: <ProgramPackages rnxMembershipProgram={rnxMembershipProgram} />
          },

          {
            name: 'Ayarlar',
            codeName: 'settings',
            element: <ProgramSettings rnxMembershipProgram={rnxMembershipProgram} />
          },
        ]}
      />
    </>
  )
}

const ProgramPackages = ({
  rnxMembershipProgram
}) => {
  return (
    <>
      <RV.GraphTable
        modelName='RnxMembershipProgramResource'
        // detailLink='resource'
        headerText='Paketler'
        actionCtx={{
          model: {
            rnx_membership_program_id: rnxMembershipProgram.id
          }
        }}
        query={{
          filters: {
            rnx_membership_program_id: rnxMembershipProgram.id
          }
        }}
      />
    </>
  )
}

const SubPrograms = ({
  rnxMembershipProgram
}) => {
  return (
    <RV.GraphTable
      modelName='rnxMembershipProgram'
      headerText='Üyelik Türleri'
      detailLink='kind'
      showEntityActions={false}
      sx={{
        mt: 3
      }}
      // detailLink='program'
      actionCtx={{
        model: {
          parent_id: rnxMembershipProgram.id
        }
      }}
      query={{
        filters: {
          parent_id: rnxMembershipProgram.id
        }
      }}
    />
  )
}

const ProgramSettings = ({
  rnxMembershipProgram
}) => {
  const formUrl = `/forms/${rnxMembershipProgram.rcEntityId}`
  return (
    <Box>
      <RV.Card>
        Form Url: <a href={formUrl} target='_blank'>{formUrl}</a>
      </RV.Card>
      <ProgramMembershipParameters rnxMembershipProgram={rnxMembershipProgram} />
      <ProgramContracts rnxMembershipProgram={rnxMembershipProgram} />
    </Box>
  )
}

const ProgramContracts = ({
  rnxMembershipProgram
}) => {
  const result = useReactorQuery({
    rnxMembershipProgram: {
      fields: [
        'contracts'
      ],
      filters: {
        id: rnxMembershipProgram.id
      }
    }
  })

  const rnxMembershipProgramWithContracts = result.graph?.rnxMembershipProgram
  if (!rnxMembershipProgramWithContracts) return null
  console.log('cont', rnxMembershipProgramWithContracts.contracts)
  return (
    <RV.Card mt={4}>
      <RV.Card.Header>
        Sözleşmeler
      </RV.Card.Header>
      <Box>
        <ActionGroup name={'RnxMembershipProgram'} contexts={['Entity.Contract']} kinds={['CREATE']} ctx={{
          id: rnxMembershipProgram.id,
        }} />
      </Box>
    </RV.Card>
  )
}

const ProgramMembershipParameters = ({
  rnxMembershipProgram
}) => {
  const result = useReactorQuery({
    rnxMembershipProgram: {
      fields: [
        'defaultMembershipParameters', 'customMembershipParameters'
      ],
      filters: {
        id: rnxMembershipProgram.id
      }
    }
  })

  const rnxMembershipProgramWithMembershipParameters = result.graph?.rnxMembershipProgram
  console.log('rs', result)
  if (!rnxMembershipProgramWithMembershipParameters) return null
  console.log('cust', rnxMembershipProgramWithMembershipParameters.customMembershipParameters)
  return (
    <Box sx={{
      mt: 4
    }}>
      <Box>
        <ActionGroup name={'RnxMembershipProgram'} contexts={['Entity.Parameter']} kinds={['UPDATE']} ctx={{
          id: rnxMembershipProgram.id,
          kind: 'parameter'
        }} />
      </Box>
      <Flex sx={{
        mx: -4,
        mt: 4
      }}>
        <RV.Card sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Varsayılan Parametreler
          </Box>

          <Box>
            {rnxMembershipProgramWithMembershipParameters.defaultMembershipParameters?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.label}
                </Box>
              )
            })}
          </Box>
        </RV.Card>

        <RV.Card sx={{
          flex: '1 1 0',
          mx: 4
        }}>
          <Box sx={{
            fontWeight: 'bold',
            // fontSize: 'lg',
            mb: 2
          }}>
            Özel Parametreler
          </Box>

          <Box>
            {rnxMembershipProgramWithMembershipParameters.customMembershipParameters?.map((param, dx) => {
              return (
                <Box key={dx}>
                  {param.name}
                </Box>
              )
            })}
          </Box>
        </RV.Card>
      </Flex>
    </Box>
  )
}

export default RnxMembershipManagement