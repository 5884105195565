import React from 'react'

// Reactor UI
import * as changeCase from 'reactor-ui/util/text'

// Reactor
import RfActionForm from 'reactor-form/components/RfActionForm'
import DynamicComponent from 'reactor-vera/apps/blocks/components/DynamicComponent'
import { useParams } from 'reactor-vera/router'


const ReactorAction = ({
  action,
  displayAs,
  onClose,
  onSuccess,
  anchorKind,
  anchor,
  initialValues,
}) => {
  const params = useParams()

  if (!action.meta.ui) return null

  if (action.meta.ui.component) {
    return <DynamicComponent name={action.meta.ui.component}/>
  }

  return (
    <RfActionForm
      actionKey={`${action.elementName}.${changeCase.pascal(action.name)}`}
      {...action.meta.ui}
      initialValues={{
        ...action.meta.ui.initialValues,
        ...initialValues,
      }}
      displayAs={displayAs || action.meta.ui.displayAs}
      onClose={onClose}
      onSuccess={onSuccess}
      anchorKind={anchorKind}
      anchor={anchor}
      ctx={{
        params
      }}
      formComponent={action.meta.ui.formComponent ? <DynamicComponent name={action.meta.ui.formComponent}/> : null}
    />
  )
}

export default ReactorAction