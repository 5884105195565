import React from 'react'

// Reactor
import useReactorAction from 'reactor/hooks/useReactorAction'

import { getFormData } from '../util'
import useFormReducer from './useFormReducer'

export const useReactorActionForm = (
  actionIdentifier,
  options = {}
) => {
  const dispatchAction = useReactorAction(actionIdentifier, {kind: options.actionKind})

  const [formState, formActions] = useFormReducer()

  const { onSuccess, onError, onStart, ...restOptions } = options
  const { phase, error, result, isSubmitting, key } = formState

  const handleSubmit = (data) => {
    formActions.BEGIN()
    if (options.parseData) data = options.parseData(data)
    const dataToSend = getFormData(data)

    return new Promise(resolve => {
      dispatchAction(dataToSend, {
        success: (payload) => {
          formActions.SUCCESS(payload)
          onSuccess?.(payload, {reset: formActions.RESET})
          resolve()
        },
        start: (payload) => {
          onStart?.(payload, {reset: formActions.RESET})
        },
        error: (payload) => {
          formActions.ERROR(payload)
          onError?.(payload, {reset: formActions.RESET})
          resolve(payload?.errors)
        }
      })
    })
  }

  const submit = () => {
    options.id && document.getElementById(options.id).dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }))
  }

  return ([{
    // ...props,
    ...restOptions,
    phase,
    error,
    handleSubmit,
    result,
    id: options.id,
    key
  }, isSubmitting, formActions.RESET, submit])
}

export default useReactorActionForm