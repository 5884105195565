import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'


export const Col3 = ({
  children,
  colSize = '300px',
  gap = 4
}) => {
  return (
    <Flex>
      {React.Children.map(children, (c, dx) => {
        const rem = dx % 3
        let sx
        if (rem === 0 || rem === 2) {
          sx = {
            ...sx,
            flex: `0 0 ${colSize}`,
            maxWidth: colSize
          }
        } else {
          sx = {
            ...sx,
            flex: `1 1 0`,
            mx: gap
          }
        }
        return (
          <Box sx={{
            ...sx
          }} key={dx}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export const Cols = ({
  children,
  gap = 4,
  sx
}) => {
  const count = React.Children.count(children)
  return (
    <Flex sx={{
      mx: -gap,
      ...sx
    }}>
      {React.Children.map(children, (c, dx) => {
        let sx = {
          flex: `1 1 0`,
          mx: gap
        }
        return (
          <Box sx={{
            ...sx
          }} key={dx}>
            {c}
          </Box>
        )
      })}
    </Flex>
  )
}

export default {
  Col3,
  Cols
}